import { createRouter, createWebHistory } from "vue-router";
import EventRoutes from "./eventRoutes";
import SystemDashboardRoute from "./systemDashboardRoute";
import ClubDetailsRoute from "./clubDetailsRoute";
import VoucherRoutes from "./voucherRoutes";
import campaignAudience from "./campaignAudience";
import digitalPassAudience from "./digitalPassAudience";
import CampaignRoutes from "./campaignRoutes";
import DigitalPass from "./digitalPass";
import SettingsRoute from "./settings";
import Audience from "./audience";
import VoucherAudience from "./voucherAudience";
import { auth } from "@/services/auth.service";
import { configuration } from "@/configurationProvider";
import { userCanAccessClubOrNot } from "@/services/clubDetails/others.service";
import flowAutomationRoutes from "./flowAutomationRoutes";
import formBuilderRoutes from "./formBuilderRoutes";
import invitation from "@/components/auth/invitation/invitation.vue";

const routes = [
  {
    path: "/authcallback",
    name: "authcallback",
    props: true,
    params: true,
    component: () => import("../components/auth/callback/callback.vue"),
  },
  {
    path: "/authcallbacksignout",
    name: "authcallbacksignout",
    props: true,
    params: true,
    component: () => import("../components/auth/callback/signoutCallback.vue"),
  },
  {
    path: "/invitation",
    name: "invitation",
    props: true,
    params: true,
    component: invitation,
  },
  {
    path: "/page-not-found",
    name: "pageNotFound",
    component: () =>
      import("@/components/common/pageNotFound/pageNotFound.vue"),
  },
  ...SystemDashboardRoute,
  ...ClubDetailsRoute,
  ...DigitalPass,
  ...EventRoutes,
  ...SettingsRoute,
  ...Audience,
  ...VoucherRoutes,
  ...CampaignRoutes,
  ...flowAutomationRoutes,
  ...VoucherAudience,
  ...campaignAudience,
  ...digitalPassAudience,
  ...formBuilderRoutes,
  {
    path: "/digitalpass/generate",
    name: "DigitalpassGenerate",
    props: true,
    params: true,
    component: () => import("../components/passGenerator/passGenerator.vue"),
  },
  {
    path: "/subscribeUnsubscribeECModal/:UId",
    name: "SubscribeUnsubscribeECModal",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/subscribeUnsubscribeECModal/subscribeUnsubscribeECModal.vue"
      ),
  },
  {
    path: "/updatePreferences/:UId",
    name: "UpdatePreferences",
    props: true,
    params: true,
    component: () =>
      import("../components/UpdatePreferences/updatePreferences.vue"),
  },
  {
    path: "/view-in-browser/:UId",
    name: "viewInBrowser",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/emailTemplateEditor/utilityComponents/viewInYourBrowserComponent.vue"
      ),
  },
  {
    path: "/login",
    redirect: "/", // redirect to home page
  },
  {
    path: "/email-template-editor",
    name: "EmailTemplateEditorCreate",
    props: true,
    params: true,
    meta: { requiresAuth: true },
    component: () =>
      import("../components/emailTemplateEditor/emailTemplateEditor.vue"),
  },
  {
    path: "/template-editor/:templateId/edit",
    name: "EmailTemplateEditorUpdate",
    props: true,
    params: true,
    meta: { requiresAuth: true },
    component: () =>
      import("../components/emailTemplateEditor/emailTemplateEditor.vue"),
  },
  {
    path: "/club-details/:id/flow-automation-editor",
    name: "workflowEditor",
    props: true,
    params: true,
    meta: { requiresAuth: true },
    component: () =>
      import("../components/workflowAutomation/emailWorkFlowEditor.vue"),
  },
  {
    path: "/club-details/:id/flow-automation/:flowId/edit",
    name: "workflowEditorUpdate",
    props: true,
    params: true,
    meta: { requiresAuth: true },
    component: () =>
      import("../components/workflowAutomation/emailWorkFlowEditor.vue"),
  },
  {
    path: "/club-details/:id/email-log",
    name: "emailLog",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Log",
          to: {
            name: "emailLog",
            props: true,
          },
        },
      ],
      requiresAuth: true,
    },
    component: () => import("@/components/clubDetails/emailLog/emailLog.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import("@/components/common/pageNotFound/pageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const encodedUrl = encodeURI(configuration.appBaseUrl + to.fullPath);
  localStorage.setItem("RedirectUrl", encodedUrl);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // ;

    if (configuration.enableIdentityServer) {
      auth.getUser().then((x) => {
        if (!x || x.expired) {
          auth
            .login()
            .then((x) => {})
            .catch((x) => {});
        } else {
          next();
        }
      });
    } else {
      if (localStorage.getItem("clubeez_session")) {
        next();
      } else {
        next("/");
      }
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

// router.beforeResolve(async (to, from, next) => {
//   if (to?.params?.id) {
//     const payload = {
//       clubId: to?.params?.id,
//       userId: JSON.parse(localStorage.getItem("user"))?.id,
//     };
//     userCanAccessClubOrNot(payload)
//       .then((result) => {
//         next();
//       })
//       .catch((error) => {
//         next("/");
//       });
//   } else {
//     next();
//   }
// });

export default router;
