import { DELETE, GET, POST, PUT } from "@/service";

export const getVoucherReportService = (payload: any) => {
  return GET(`api/v1/vouchers/reports`, {
    params: payload,
  });
};

export const exportVoucherReportService = (payload: any) => {
  return POST(
    `api/v1/vouchers/reports/export`,
    payload
  );
};

export const reportGen = (payload: any) => {
  return POST(
    `api/v1/System/generate-VoucherReport`,
    payload
  );
};
