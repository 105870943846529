import { userValidation } from "../utils/helper";

const clubDetailsRoute = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/dashboard",
    name: "ClubDashboard",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/clubDashboard/ClubDashboard.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/member-list",
    name: "MembersList",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Members" },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/clubDetails/members/Members.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/member-list/member-details/:memberId",
    name: "MemberDetails",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "member-list",
          to: {
            name: "MembersList",
            props: true,
          },
        },
        { text: "Members" },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/clubDetails/members/memberDetails/memberDetails.vue"),
  },
  {
    path: "/club-details/:id/user-profile",
    name: "UserProfile",
    props: true,
    meta: {
      // breadcrumb: [
      //     { name: 'Home', link: '/' },
      //     { name: 'clubname' },
      //     { name: 'My Profile' },
      // ],
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "My Profile" },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/auth/userProfile/UserProfile.vue"),
  },
  {
    path: "/club-details/:id/",
    name: "LeftSideBar",
    props: true,
    component: () =>
      import("../components/clubDetails/leftSidebar/LeftSideBar.vue"),
    meta: { requiresAuth: true },
  },
];

export default clubDetailsRoute;
