import {
  getFormExternalPages,
  getFormExternalPosts,
  getFormExternalTags,
  getFormExternalPostCategories,
  getFormProfileFieldsService
} from "@/services/clubDetails/form.external.service";
import {
  getWebCommanderCategoryService,
  getWebCommanderProductService,
} from "@/services/clubDetails/productWidget.service";

import { getFormCountries } from "@/services/clubDetails/form.service";

export type State = {
  countryList: Object;
  formExternalPages: Object;
  formExternalPosts: Object;
  formExternalTags: Object;
  formExternalPostCategories: Object;
  formExternalCategories: Object;
  formExternalProducts: Object;
  formExternalProfileFields: Object;

  isCountryApiLoading: Boolean;
  isExternalPageApiLoading: Boolean;
  isExternalPostApiLoading: Boolean;
  isExternalTagsApiLoading: Boolean;
  isExternalPostCategoriesApiLoading: Boolean;
  isExternalCategoriesApiLoading: Boolean;
  isExternalProductsApiLoading: Boolean;
  isExternalProfileFieldsApiLoading: Boolean;
};

const state: State = {
  countryList: [],
  formExternalPages: [],
  formExternalPosts: [],
  formExternalPostCategories: [],
  formExternalTags: [],
  formExternalCategories: [],
  formExternalProducts: [],
  formExternalProfileFields: [],

  isCountryApiLoading: false,
  isExternalPageApiLoading: false,
  isExternalPostApiLoading: false,
  isExternalTagsApiLoading: false,
  isExternalPostCategoriesApiLoading: false,
  isExternalCategoriesApiLoading: false,
  isExternalProductsApiLoading: false,
  isExternalProfileFieldsApiLoading: false,
};

const emailCampaignForms = {
  state,
  getters: {
    countryList: (state: State) => state.countryList,
    formExternalPages: (state: State) => state.formExternalPages,
    formExternalPosts: (state: State) => state.formExternalPosts,
    formExternalTags: (state: State) => state.formExternalTags,
    formExternalPostCategories: (state: State) => state.formExternalPostCategories,
    formExternalCategories: (state: State) => state.formExternalCategories,
    formExternalProducts: (state: State) => state.formExternalProducts,
    formExternalProfileFields: (state: State) =>
      state.formExternalProfileFields,

    isCountryApiLoading: (state: State) => state.isCountryApiLoading,
    isExternalPageApiLoading: (state: State) => state.isExternalPageApiLoading,
    isExternalPostApiLoading: (state: State) => state.isExternalPostApiLoading,
    isExternalTagsApiLoading: (state: State) => state.isExternalTagsApiLoading,
    isExternalCategoriesApiLoading: (state: State) =>
      state.isExternalCategoriesApiLoading,
    isExternalProductsApiLoading: (state: State) =>
      state.isExternalProductsApiLoading,
    isExternalProfileFieldsApiLoading: (state: State) =>
      state.isExternalProfileFieldsApiLoading,
  },
  mutations: {
    setCountryList(state: State, payload: Object[]) {
      state.countryList = payload;
    },
    setFormExternalPages(state: State, payload: any) {
      state.formExternalPages = payload;
    },
    setFormExternalPosts(state: State, payload: any) {
      state.formExternalPosts = payload;
    },
    setFormExternalTags(state: State, payload: any) {
      state.formExternalTags = payload;
    },
    setFormExternalPostCategories(state: State, payload: any) {
      state.formExternalPostCategories = payload;
    },
    setFormExternalCategories(state: State, payload: any) {
      state.formExternalCategories = payload;
    },
    setFormExternalProducts(state: State, payload: any) {
      state.formExternalProducts = payload;
    },
    setFormExternalProfileFields(state: State, payload: any) {
      state.formExternalProfileFields = payload;
    },

    setIsCountryApiLoading(state: State, payload: Boolean) {
      state.isCountryApiLoading = payload;
    },
    setIsExternalPageApiLoading(state: State, payload: Boolean) {
      state.isExternalPageApiLoading = payload;
    },
    setIsExternalPostApiLoading(state: State, payload: Boolean) {
      state.isExternalPostApiLoading = payload;
    },
    setIsExternalTagsApiLoading(state: State, payload: Boolean) {
      state.isExternalTagsApiLoading = payload;
    },
    setIsExternalPostCategoriesLoading(state: State, payload: Boolean) {
      state.isExternalPostCategoriesApiLoading = payload;
    },
    setIsExternalCategoriesApiLoading(state: State, payload: Boolean) {
      state.isExternalCategoriesApiLoading = payload;
    },
    setIsExternalProductsApiLoading(state: State, payload: Boolean) {
      state.isExternalProductsApiLoading = payload;
    },
    setIsExternalProfileFieldsApiLoading(state: State, payload: Boolean) {
      state.isExternalProfileFieldsApiLoading = payload;
    },
  },
  actions: {
    async getFormCountries({ commit }: any, payload: any) {
      commit("setIsCountryApiLoading", true);
      const response = await getFormCountries(payload);
      commit("setCountryList", response?.data || []);
      commit("setIsCountryApiLoading", false);
    },

    async getFormExternalPages({ commit }: any, payload: any) {
      commit("setIsExternalPageApiLoading", true);
      const response = await getFormExternalPages(payload);
      commit("setFormExternalPages", response.data?.data);
      commit("setIsExternalPageApiLoading", false);
    },
    async getFormExternalPosts({ commit }: any, payload: any) {
      commit("setIsExternalPostApiLoading", true);
      const response = await getFormExternalPosts(payload);
      commit("setFormExternalPosts", response.data?.data);
      commit("setIsExternalPostApiLoading", false);
    },
    async getFormExternalTags({ commit }: any, payload: any) {
      commit("setIsExternalTagsApiLoading", true);
      const response = await getFormExternalTags(payload);
      commit("setFormExternalTags", response.data?.data);
      commit("setIsExternalTagsApiLoading", false);
    },
    async getFormExternalPostCategories({ commit }: any, payload: any) {
      commit("setIsExternalPostCategoriesLoading", true);
      const response = await getFormExternalPostCategories(payload);
      commit("setFormExternalPostCategories", response.data?.data);
      commit("setIsExternalPostCategoriesLoading", false);
    },
    async getFormExternalCategories({ commit }: any, payload: any) {
      commit("setIsExternalCategoriesApiLoading", true);
      const response = await getWebCommanderCategoryService(payload);
      commit("setFormExternalCategories", response.data?.categories);
      commit("setIsExternalCategoriesApiLoading", false);
    },
    async getFormExternalProducts({ commit }: any, payload: any) {
      commit("setIsExternalProductsApiLoading", true);
      const response = await getWebCommanderProductService(payload);
      commit("setFormExternalProducts", response.data?.products);
      commit("setIsExternalProductsApiLoading", false);
    },
    async getFormExternalProfileFields({ commit }: any, payload: any) {
      commit("setIsExternalProfileFieldsApiLoading", true);
      const response = await getFormProfileFieldsService(payload);
      commit("setFormExternalProfileFields", response?.data?.data);
      commit("setIsExternalProfileFieldsApiLoading", false);
    },
  },
};
export default emailCampaignForms;
