<template>
  <div class="center">
    <h1>List Event</h1>
    <h2>Total Event: {{totalEvent}}</h2>
    <router-link :to="`/addevent`">
           <button>Add Event</button>
    </router-link>
    <table border="1px">
      <tr>
        <td>Id</td>
        <td>Name</td>
        <td>Description</td>
        <td>Action</td>
      </tr>
      <tr v-for="(event, index) in eventList" :key="index">
        <td>{{event.id}}</td>
        <td>{{event.name}}</td>
        <td>{{event.description}}</td>
        <router-link :to="`/event/${event.id}`">
           <button>Details</button>
        </router-link>
        <router-link :to="`/event/${event.id}/update`">
           <button>Update</button>
        </router-link>
        <button @click="removeEvent(index)">Remove</button>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  computed:{
     eventList(){
         return this.$store.state.events.events
     },
     totalEvent(){
         return this.$store.getters.totalEvent
     }
  },
  methods:{
     removeEvent(index){
        this.$store.dispatch("removeEvent", index)
     }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
center {
    justify-content:center;
    align-items:center;
    text-align:center;
}
</style>
