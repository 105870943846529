export const clubeezBaseUrl = 'https://stage-api.clubeez.com/' 

export const environment = <Config>{
    
    enableIdentityServer: true,

    identity: <IdentityConfig>{
        authority: 'https://stage-identity.clubeez.com',
        clientId: 'vue id staging',
        clientSecret: 'vue secret staging',
        redirectUri: 'https://stage-app.clubeez.com/authcallback',
        postLogoutRedirectUri: 'https://stage-app.clubeez.com/authcallbacksignout'
    },

    apiBaseUrl: 'https://stage-api.clubeez.com',
    appBaseUrl: 'https://stage-app.clubeez.com'
} 
