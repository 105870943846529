import { DELETE, GET, POST, PUT } from "@/service";
import { useRoute } from "vue-router";

export const getLists = (model: any) => {
  model.clubId = model?.clubId ? model?.clubId : useRoute()?.params?.id;
  return GET(`api/v1/Audience/list`, { params: model });
};

export const getListById = (payload: any) => {
  return GET(`api/v1/Audience/list/${payload?.id}`, { params: payload });
};

export const getPremiumSubscriber = (model: any) => {
  model.clubId = model?.clubId ? model?.clubId : useRoute()?.params?.id;
  return GET(`api/v1/Audience/list/${model.listId || 0}/members`, { params: model });
};

export const addList = (model: any) => {
  model.clubId = model?.clubId ? model?.clubId : useRoute()?.params?.id;
  return POST(`api/v1/Audience/list`, model);
};

export const renameList = (model: any) => {
  model.clubId = model?.clubId ? model?.clubId : useRoute()?.params?.id;
  return PUT(`api/v1/Audience/list/${model.id}/rename`, model);
};

export const changeActiveStatus = (model: any) => {
  model.clubId = model?.clubId ? model?.clubId : useRoute()?.params?.id;
  return PUT(`api/v1/Audience/list/${model.id}/change-active-status`, model);
};

export const removeList = (model: any) => {
  model.clubId = model?.clubId ? model?.clubId : useRoute()?.params?.id;
  return DELETE(`api/v1/Audience/list`, { data: model });
};
