<template src="./AppPopUp.html"></template>
<script>
import "./styles.css";
import { Popup } from "@progress/kendo-vue-popup";
import { Button } from "@progress/kendo-vue-buttons";
import { Menu } from "@progress/kendo-vue-layout";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";

export default {
  components: {
    Popup: Popup,
    kbutton: Button,
    "kendo-menu": Menu,
    dropdownlist: DropDownList,
    DropDownButton,
  },
  props: {
    buttonClass: {
      type: String,
    },
    popUpSettings: {},
    items: {
      type: [],
      required: true,
    },
    voucher: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
    popupText: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      anchor: null,
      anchorHorizontal: "right",
      anchorVertical: "bottom",
      popupHorizontal: "right",
      popupVertical: "top",
      commonItems: this.items,
      item: "",
    };
  },
  computed: {
    buttonText() {
      return this.show ? "Hide" : "Show";
    },
    anchorAlign() {
      return {
        horizontal: this.anchorHorizontal,
        vertical: this.anchorVertical,
      };
    },
    popupAlign() {
      return {
        horizontal: this.popupHorizontal,
        vertical: this.popupVertical,
      };
    },
  },
  methods: {
    onMenuSelect(e) {
      this.$emit("dropId", e.item?.dropId, this.voucher);
    },
    onOpen(){
      this.$emit("onOpen", this.voucher);
    }
  },
};
</script>
