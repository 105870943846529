import {
  getCampaignVariationService,
  getCampaignPerformanceOvertimeService,
  getCampaignOverallPerformanceService,
  getCampaignPerformanceByLocationService,
  getCampaignClientDeviceService,
  getCampaignPerformanceBenchmarkService,
} from "@/services/clubDetails/campaignReport/campaignReport.service";
import { percentageCalculator } from "@/utils/helper";
import { countries } from "@/utils/helperObjects";

export type State = {
  campaignVariationsLoader: Boolean;
  campaignVariationsData: Object;

  campaignOverallPerformanceData: Object;
  campaignOverallPerformanceLoader: Boolean;

  campaignPerformanceOverTimeData: Object;
  campaignPerformanceOverTimeLoader: Boolean;

  campaignClientDevicesData: Object;
  campaignClientDevicesLoader: Boolean;

  campaignPerformanceByLocationData: Object;
  campaignPerformanceByLocationLoader: Boolean;

  campaignPerformanceBenchmarkData: Object;
  campaignPerformanceBenchmarkLoader: Boolean;
};

const state: State = {
  campaignVariationsLoader: false,
  campaignVariationsData: [],

  campaignOverallPerformanceData: {},
  campaignOverallPerformanceLoader: false,

  campaignPerformanceOverTimeData: {},
  campaignPerformanceOverTimeLoader: false,

  campaignClientDevicesData: {},
  campaignClientDevicesLoader: false,

  campaignPerformanceByLocationData: [],
  campaignPerformanceByLocationLoader: false,

  campaignPerformanceBenchmarkData: [],
  campaignPerformanceBenchmarkLoader: false,
};

const emailCampaignAdvanceReport = {
  state,
  getters: {},
  mutations: {
    SET_CAMPAIGN_VARIATIONS_LOADER(
      state: { campaignVariationsLoader: boolean },
      data: boolean
    ) {
      state.campaignVariationsLoader = data;
    },
    SET_CAMPAIGN_VARIATIONS_DATA(
      state: { campaignVariationsData: Object },
      data: Object
    ) {
      state.campaignVariationsData = data;
    },
    SET_CAMPAIGN_OVERALL_PERFORMANCE_LOADER(
      state: { campaignOverallPerformanceLoader: boolean },
      data: boolean
    ) {
      state.campaignOverallPerformanceLoader = data;
    },
    SET_CAMPAIGN_OVERALL_PERFORMANCE_DATA(
      state: { campaignOverallPerformanceData: Object },
      data: Object
    ) {
      state.campaignOverallPerformanceData = data;
    },
    SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER(
      state: { campaignPerformanceOverTimeLoader: boolean },
      data: boolean
    ) {
      state.campaignPerformanceOverTimeLoader = data;
    },
    SET_CAMPAIGN_PERFORMANCE_OVER_TIME_DATA(
      state: { campaignPerformanceOverTimeData: Object },
      data: Object
    ) {
      state.campaignPerformanceOverTimeData = data;
    },
    SET_CAMPAIGN_CLIENT_DEVICES_LOADER(
      state: { campaignClientDevicesLoader: boolean },
      data: boolean
    ) {
      state.campaignClientDevicesLoader = data;
    },
    SET_CAMPAIGN_CLIENT_DEVICES_DATA(
      state: { campaignClientDevicesData: Object },
      data: Object
    ) {
      state.campaignClientDevicesData = data;
    },
    SET_CAMPAIGN_PERFORMANCE_BY_LOCATION_LOADER(
      state: { campaignPerformanceByLocationLoader: boolean },
      data: boolean
    ) {
      state.campaignPerformanceByLocationLoader = data;
    },
    SET_CAMPAIGN_PERFORMANCE_BY_LOCATION_DATA(
      state: { campaignPerformanceByLocationData: Object },
      data: Object
    ) {
      state.campaignPerformanceByLocationData = data;
    },
    SET_CAMPAIGN_PERFORMANCE_BENCHMARK_LOADER(
      state: { campaignPerformanceBenchmarkLoader: boolean },
      data: boolean
    ) {
      state.campaignPerformanceBenchmarkLoader = data;
    },
    SET_CAMPAIGN_PERFORMANCE_BENCHMARK_DATA(
      state: { campaignPerformanceBenchmarkData: Object },
      data: Object
    ) {
      state.campaignPerformanceBenchmarkData = data;
    },
  },
  actions: {
    async getCampaignVariations(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_CAMPAIGN_VARIATIONS_LOADER", true);
      try {
        const response = await getCampaignVariationService(payload);
        const data = response?.data?.data;
        data.unshift({ id: null, variationNo: "All Variation" });
        commit("SET_CAMPAIGN_VARIATIONS_DATA", response?.data?.data);
        // ;
        commit("SET_CAMPAIGN_VARIATIONS_LOADER", false);
      } catch (error) {
        commit("SET_CAMPAIGN_VARIATIONS_LOADER", false);
        throw error;
      }
    },
    async getCampaignOverallPerformance(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_CAMPAIGN_OVERALL_PERFORMANCE_LOADER", true);
      try {
        const response = await getCampaignOverallPerformanceService(payload);
        commit("SET_CAMPAIGN_OVERALL_PERFORMANCE_DATA", response?.data?.data);
        // ;
        commit("SET_CAMPAIGN_OVERALL_PERFORMANCE_LOADER", false);
      } catch (error) {
        commit("SET_CAMPAIGN_OVERALL_PERFORMANCE_LOADER", false);
        throw error;
      }
    },
    async getCampaignPerformanceOverTime(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER", true);
      try {
        const response = await getCampaignPerformanceOvertimeService(payload);
        commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_DATA", response?.data?.data);
        // ;
        commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER", false);
      } catch (error) {
        commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER", false);
        throw error;
      }
    },
    async getCampaignClientDevices(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_CAMPAIGN_CLIENT_DEVICES_LOADER", true);
      try {
        const response = await getCampaignClientDeviceService(payload);
        const data = response?.data?.data;
        //for of loop
        let overAllData = [];
        let desktopData = [];
        let mobileData = [];
        let totalDesktop = 0;
        let totalMobile = 0;
        for (const element of data) {
          let obj = {
            ...element,
            value: (
              (element?.User_Per_Group / element?.Total_User) *
              100
            ).toFixed(1),
            category: element?.UserAgent,
          };
          overAllData.push(obj);
          if (obj?.DeviceType === "Desktop") {
            if (obj?.value) {
              totalDesktop += Number(obj?.value);
              desktopData.push(obj);
            }
          }
          if (obj?.DeviceType === "Mobile") {
            if (obj?.value) {
              totalMobile += Number(obj?.value);
              mobileData.push(obj);
            }
          }
        }

        const overAllDataObj = {
          overall: overAllData,
          desktop: desktopData,
          mobile: mobileData,
          selected: overAllData,
          totalDesktop,
          totalMobile,
        };

        commit("SET_CAMPAIGN_CLIENT_DEVICES_DATA", overAllDataObj);
        commit("SET_CAMPAIGN_CLIENT_DEVICES_LOADER", false);
      } catch (error) {
        commit("SET_CAMPAIGN_CLIENT_DEVICES_LOADER", false);
        throw error;
      }
    },
    async getCampaignPerformanceByLocation(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_CAMPAIGN_PERFORMANCE_BY_LOCATION_LOADER", true);
      try {
        const response = await getCampaignPerformanceByLocationService(payload);
        const data = response?.data?.data;
        let overAllData = [];
        for (const element of data) {
          let obj = {
            ...element,
            value: Math.round(
              (element?.User_Per_Group / element?.Total_User) * 100
            ),
            // @ts-ignore
            category: countries[element?.Country],
          };
          overAllData.push(obj);
        }

        commit("SET_CAMPAIGN_PERFORMANCE_BY_LOCATION_DATA", overAllData);
        commit("SET_CAMPAIGN_PERFORMANCE_BY_LOCATION_LOADER", false);
      } catch (error) {
        commit("SET_CAMPAIGN_PERFORMANCE_BY_LOCATION_LOADER", false);
        throw error;
      }
    },
    async getCampaignPerformanceBenchmark(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_CAMPAIGN_PERFORMANCE_BENCHMARK_LOADER", true);
      try {
        const response = await getCampaignPerformanceBenchmarkService(payload);
        const data = response?.data?.data;
        let overAllData = [];
        for (const element of data) {
          let obj = {
            ...element,
            clickRate: percentageCalculator(
              element?.UniqueClicked,
              element?.UniqueDelivered
            ),
            dataValue: [
              percentageCalculator(element?.UniqueClicked, element?.UniqueSent),
            ],
            openRate: percentageCalculator(
              element?.UniqueOpened,
              element?.UniqueDelivered
            ),
            converted: percentageCalculator(
              element?.TotalConversion,
              element?.UniqueDelivered
            ),
            bouncedRate: percentageCalculator(
              element?.UniqueBounced,
              element?.UniqueSent
            ),
            name: element?.CampaignName,
          };
          overAllData.push(obj);
        }

        commit("SET_CAMPAIGN_PERFORMANCE_BENCHMARK_DATA", overAllData);
        // ;
        commit("SET_CAMPAIGN_PERFORMANCE_BENCHMARK_LOADER", false);
      } catch (error) {
        commit("SET_CAMPAIGN_PERFORMANCE_BENCHMARK_LOADER", false);
        throw error;
      }
    },
  },
};
export default emailCampaignAdvanceReport;
