<template src="./ClubDetails.html"></template>
<script>
import LeftSideBar from "./leftSidebar/LeftSideBar";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  components: {
    LeftSideBar,
    AppLoader,
  },

  data() {
    return {
      resPonsiveSidebarOpen: "noAction",
    };
  },

  created() {
    window.addEventListener("resize", this.widthChangeHandler);
    window.addEventListener("popstate", this.widthChangeHandler);
  },

  watch: {
    $route() {
      if (
        this.$refs.infoBox.clientWidth <= 1024 &&
        (this.$route.query.id || this.$route.params.id)
      ) {
        this.resPonsiveSidebarOpen = "close";
      } else {
        this.resPonsiveSidebarOpen = "noAction";
      }
    },
  },
  computed: {
    isPageNotFoundVisible() {
      return this.$store.state.common.isPageNotFoundVisible;
    },
    isMenuLoading() {
      return this.$store.state.common.isMenuLoading;
    },
  },
  mounted() {
    if (!this.$route.params?.id) {
      this.$store.commit("SET_MENU_LOADING", false);
    }

    if (this.$route.params.id || this.$route.query.club_id) {
      this.$store.dispatch("getClub", {
        clubId: this.$route.params.id || this.$route.query.club_id,
        loadMenu: true,
      });
    }

    if (
      this.$refs.infoBox.clientWidth <= 1024 &&
      (this.$route.query.id || this.$route.params.id)
    ) {
      this.resPonsiveSidebarOpen = "close";
    } else {
      this.resPonsiveSidebarOpen = "noAction";
    }
  },

  unmounted() {
    window.removeEventListener("resize", this.widthChangeHandler);
    window.removeEventListener("popstate", this.widthChangeHandler);
  },

  methods: {
    widthChangeHandler() {
      const windowsWidth = this.$refs.infoBox.clientWidth;
      if (
        windowsWidth <= 1024 &&
        (this.$route.query.id || this.$route.params.id)
      ) {
        this.resPonsiveSidebarOpen = "close";
      } else {
        this.resPonsiveSidebarOpen = "noAction";
      }
    },

    sidebarToggleHandler() {
      if (this.resPonsiveSidebarOpen === "close") {
        this.resPonsiveSidebarOpen = "open";
      } else {
        this.resPonsiveSidebarOpen = "close";
      }
    },
  },
};
</script>
