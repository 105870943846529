import { DELETE, GET, POST, PUT } from "@/service";

export const getVoucherRecipientsService = (payload: any) => {
  return GET(`api/v1/vouchers/${payload.voucherCampaignId}/recipients`, {
    params: payload,
  });
};

export const addMembersToVoucherCampaignService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/add`,
    payload
  );
};

export const addListMembersToVoucherCampaignService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/add-from-audience-list`,
    payload
  );
};

export const aaddListMembersToVoucherCampaignService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/add-from-audience-list`,
    payload
  );
};

export const importVoucherFieldsMappedDataService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/import`,
    payload
  );
};

export const deleteVoucherRecipientsService = (payload: any) => {
  return DELETE(`api/v1/Vouchers/${payload.voucherCampaignId}/recipients`, {
    data: payload,
  });
};
export const deleteUnassignedVoucherRecipientsService = (payload: any) => {
  return POST(
    `api/v1/Vouchers/${payload.voucherCampaignId}/recipients/${payload.voucherRecipientId}/delete-unassigned-code`,
    payload
  );
};

export const generataeVoucherCodeService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/generate-voucher-code`,
    payload
  );
};
export const generateUnassignedVoucherCodeService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/generate-unassigned-voucher-code`,
    payload
  );
};
export const assignAudienceToVoucherCodeService = (payload: any) => {
  return POST(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/${payload.voucherRecipientId}/assign-audience-to-voucher-code-recipient`,
    payload
  );
};

export const voucherStatusUpdateService = (payload: any) => {
  return PUT(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/${payload.voucherCampaignRecipientId}/update-status`,
    payload
  );
};

export const sendVoucherEmailService = (payload: any) => {
  return POST(
    `api/v1/Vouchers/${payload.voucherCampaignId}/recipients/send-voucher-code`,
    payload
  );
};

export const updateRefillAmountService = (payload: any) => {
  return PUT(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/refill-amount`,
    payload
  );
};

export const updateExpiryDateService = (payload: any) => {
  return PUT(
    `api/v1/vouchers/${payload.voucherCampaignId}/recipients/expiry-date`,
    payload
  );
};

export const updateStatusService = (payload: any) => {
  return PUT(`api/v1/Vouchers/recipients/update-status-bulk`, payload);
};
