import EventList from '../components/event/EventList.vue'
import EventDetails from '../components/event/EventDetails.vue'
import EventUpdate from '../components/event/EventUpdate.vue'
import EventAdd from '../components/event/EventAdd.vue'

const eventRoutes = [
  {
    path: '/addevent',
    name: 'EventAdd',
    component: EventAdd,
    meta: { requiresAuth: true, }
  },
  {
    path: '/events',
    name: 'EventList',
    component: EventList,
    meta: { requiresAuth: true, }
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: EventDetails,
    meta: { requiresAuth: true, }
  },
  {
    path: '/event/:id/update',
    name: 'EventUpdate',
    component: EventUpdate,
    meta: { requiresAuth: true, }
  },
];

export default eventRoutes;