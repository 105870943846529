<template>
  <div>
    <AppSnackBar
      v-for="obj in notifications"
      :key="obj.id"
      :message="obj.message"
      :notificationType="obj.type"
    ></AppSnackBar>
  </div>
</template>

<script>
import AppSnackBar from "../AppSnackBar/AppSnackBar.vue";
export default {
  components: {
    AppSnackBar,
  },
  data() {
    return {};
  },
  computed: {
    notifications() {
      return this.$store.state.common.notifications;
    },
  },
  methods: {},
};
</script>

