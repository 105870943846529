import { DELETE, GET, POST, PUT } from "@/service";

export const getEmailCampaignTemplate = (payload: any) => {
  return GET(`api/v1/campaign/get-email-campaign-template`, {
    params: payload,
  });
};

export const getEmailCampaignTemplateList = (payload: any) => {
  return GET(`api/v1/campaign/get-email-campaign-template-list`, {
    params: payload,
  });
};

export const createEmailCampaignTemplate = (payload: any) => {
  return POST(`api/v1/campaign/add-email-campaign-template`, payload);
};

export const updateEmailCampaignTemplate = (payload: any) => {
  return PUT(`api/v1/campaign/update-email-campaign-template`, payload);
};

export const templateSendTestEmail = (payload: any) => {
  return POST(
    `api/v1/campaign/${payload?.clubId}/email-campaign-template-test-email`,
    payload
  );
};

export const getEmailCampaignTemplateMacros = (payload: any) => {
  return GET(`api/v1/Campaign/get-email-template-macros`, {
    params: payload,
  });
};

export const deleteEmailCampaignTemplate = (model: any) => {
  return DELETE(`api/v1/Campaign/delete-email-campaign-template`, {
    data: model,
  });
};

export const getEmailTemplateUploadedImagesService = (payload: any) => {
  return GET(`api/v1/Document/get-all-media`, {
    params: payload,
  });
};

export const deleteEmailTemplateUploadedImagesService = (payload: any) => {
  return DELETE(`api/v1/Document/${payload?.clubId}/delete-files`, {
    data: payload?.documentIds,
  });
};
