import { GET } from "@/service";

export const getFormExternalPages = (payload: any) => {
  return GET(`api/v1/forms/get-external-pages`, {
    params: payload,
  });
};

export const getFormExternalPosts = (payload: any) => {
  return GET(`api/v1/forms/get-external-posts`, {
    params: payload,
  });
};

export const getFormExternalTags = (payload: any) => {
  return GET(`api/v1/forms/get-external-tags`, {
    params: payload,
  });
};

export const getFormExternalPostCategories = (payload: any) => {
  return GET(`api/v1/forms/get-external-post-categories`, {
    params: payload,
  });
};


export const getFormProfileFieldsService = (payload: any) => {
  return GET(`api/v1/forms/get-profile-fields`, {
    params: payload,
  });
};