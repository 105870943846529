import { userValidation } from "../utils/helper";

const flowAutomationRoutes = [
  {
    path: "/club-details/:id/flow-automation",
    name: "flowAutomation",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Flow Automation" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/flowAutomation/flowAutomation.vue"),
  },
  {
    path: "/club-details/:id/create-flow-automation",
    name: "createFlowAutomation",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Flow Automation",
          to: {
            name: "flowAutomation",
            props: true,
          },
        },
        { text: "Create Flow Automation" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/flowAutomation/createFlowAutomation/createFlowAutomation.vue"
      ),
  },
  {
    path: "/club-details/:id/flow-automation/:flowId",
    name: "viewFlowDetails",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Flow Automation",
          to: {
            name: "flowAutomation",
            props: true,
          },
        },
        { text: "flowAutomationName" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/flowAutomation/viewFlowDetails/viewFlowDetails.vue"
      ),
  },
  {
    path: "/club-details/:id/flow-automation/:flowId/emaillist",
    name: "flowEmailList",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Flow Automation",
          to: {
            name: "flowAutomation",
            props: true,
          },
        },
        {
          text: "flowAutomationName",
          to: {
            name: "viewFlowDetails",
            props: true,
            params: true,
          },
        },
        {
          text: "Email List",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/flowAutomation/flowAutomationEmailList/flowAutomationEmailList.vue"
      ),
  },
];

export default flowAutomationRoutes;
