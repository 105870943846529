import { DELETE, GET, POST, PUT } from "@/service";

export const getFlowDetailsService = (payload: any) => {
  return GET(`api/v1/autoflow/get-autoflow`, {
    params: payload,
  });
};

export const getFlowAutomationListService = (payload: any) => {
  return GET(`api/v1/autoflow/get-autoflow-list`, {
    params: payload,
  });
};

export const createFlowAutomationService = (payload: any) => {
  return POST(`api/v1/autoflow/add-autoflow`, payload);
};

export const archiveAutoFlowService = (payload: any) => {
  return PUT(`api/v1/autoflow/archive-autoflow`, payload);
}

export const verifyAutoFlowNameService = (payload: any) => {
  return GET(`api/v1/AutoFlow/check-autoflow`, {
    params: payload,
  });
};

export const updateFlowAutomationService = (payload: any) => {
  return PUT(`api/v1/autoflow/update-autoflow`, payload);
};

export const deleteFlowAutomationService = (payload: any) => {
  return DELETE(`api/v1/autoflow/delete-autoflow`, {
    data: payload,
  });
};

export const updateFlowAutomationStatusService = (payload: any) => {
  return PUT(`api/v1/autoflow/update-autoflow-status`, payload);
};

export const cloneFlowAutomationService = (payload: any) => {
  return POST(`api/v1/autoflow/clone-autoflow`, payload);
};

export const parsejsonTemplateService = (payload: any) => {
  return POST(`api/v1/campaign/json-template`, payload);
};

export const getMetricsTypeListService = () => {
  return GET(`api/v1/External/get-custom-event-types`);
};

export const getMetricsCustomFieldsService = (payload: any) => {
  return GET(`api/v1/External/get-custom-fields`, {
    params: payload,
  });
};

export const getBasicCampaignListService = (payload: any) => {
  return GET(`api/v1/Campaign/get-basic-campaign-list`, {
    params: payload,
  });
};

export const getProfileFieldsService = (payload: any) => {
  return GET(`api/v1/Campaign/get-profile-fields`, {
    params: payload,
  });
};

// Flow Automation Analytics

export const getFlowAutomationTriggerAnalyticsService = (payload: any) => {
  return GET(`api/v1/autoflow/get-autoflow-trigger-analytics`, {
    params: payload,
  });
};

export const getFlowAutomationActionAnalyticsService = (payload: any) => {
  return GET(`api/v1/autoflow/get-autoflow-action-analytics`, {
    params: payload,
  });
};
