import {
  getSegmentConfig,
  getSegmentList,
  getSegmentConfigConditions,
  addSegment,
  getSegment,
} from "@/services/clubDetails/segments.service";

export type State = {
  isAddNewItemModal: boolean;
  isLoading: boolean;
  segmentsLists: any;
  segmentListForDropdown: any;
  segmentData: any;
  segmentsConfigs: any;
  getSegmentConfigConditions: any;
  fetchController: "notfetched"; //Possible variable fetched , available , notavailable
  segmentModalOpenClose: boolean;
};

const state: State = {
  isAddNewItemModal: false,
  isLoading: false,
  segmentsLists: [],
  segmentListForDropdown: [],
  segmentData: [],
  segmentsConfigs: [],
  getSegmentConfigConditions: [],
  fetchController: "notfetched", //Possible variable fetched , available , notavailable
  segmentModalOpenClose: false,
};

const segments = {
  state,
  mutations: {
    SET_ALL_SEGMENTS_LIST(state: { segmentsLists: any[] }, data: any) {
      state.segmentsLists = data;
    },
    SET_SEGMENT_LIST_FOR_DROPDOWN(
      state: { segmentListForDropdown: any[] },
      data: any
    ) {
      state.segmentListForDropdown = data;
    },
    SET_SEGMENT_DATA(state: { segmentData: any[] }, data: any) {
      state.segmentData = data;
    },
    SET_SEGMENTS_CONFIGS(state: { segmentsConfigs: any[] }, data: any) {
      state.segmentsConfigs = data;
    },
    SET_SEGMENTS_CONFIG_CONDITIONS(
      state: { getSegmentConfigConditions: any[] },
      data: any
    ) {
      state.getSegmentConfigConditions = data;
    },
    SET_SEGMENTS_LOADER(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },
    SET_SEGMENT_MODALOPENCLOSE(
      state: { segmentModalOpenClose: Boolean },
      data: any
    ) {
      state.segmentModalOpenClose = data;
    },
  },
  actions: {
    getsegmentsLists({ commit }: any, payload: any) {
      commit("SET_SEGMENTS_LOADER", true);

      getSegmentList(payload)
        .then((response) => {
          commit("SET_SEGMENTS_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_ALL_SEGMENTS_LIST", response.data.data);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_SEGMENTS_LOADER", false);
        });
    },
    getsegmentListForDropdown({ commit }: any, payload: any) {
      commit("SET_SEGMENTS_LOADER", true);
      getSegmentList(payload)
        .then((response) => {
          commit("SET_SEGMENTS_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_SEGMENT_LIST_FOR_DROPDOWN", response.data.data.items);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_SEGMENTS_LOADER", false);
        });
    },

    getSegment({ commit }: any, payload: any) {
      commit("SET_SEGMENTS_LOADER", true);
      getSegment(payload)
        .then((response) => {
          commit("SET_SEGMENTS_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_SEGMENT_DATA", response.data.data);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_SEGMENTS_LOADER", false);
        });
    },
    getsegmentsConfig({ commit }: any, id: any) {
      commit("SET_SEGMENTS_LOADER", true);

      getSegmentConfig(id)
        .then((response) => {
          commit("SET_SEGMENTS_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_SEGMENTS_CONFIGS", response.data.data);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_SEGMENTS_LOADER", false);
        });
    },
    getSegmentConfigConditions({ commit }: any, id: any) {
      commit("SET_SEGMENTS_LOADER", true);

      getSegmentConfigConditions(id)
        .then((response) => {
          commit("SET_SEGMENTS_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_SEGMENTS_CONFIG_CONDITIONS", response.data.data);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_SEGMENTS_LOADER", false);
        });
    },
    addSegment({ commit }: any, model: any) {
      commit("SET_SEGMENTS_LOADER", true);

      addSegment(model)
        .then((response) => {
          commit("SET_SEGMENTS_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");

          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_SEGMENTS_LOADER", false);
        });
    },
  },
};
export default segments;
