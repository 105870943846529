import SnackBarModel from "@/models/snackBar/snackBarModel";
import { DELETE, GET } from "@/service";

export type State = {
  voucherLists: any;
  voucherStatus: SnackBarModel;
  loadingVouchers: boolean;
  showMsgVouchers: boolean;
};

const state: State = {
  voucherLists: {},
  showMsgVouchers: false,
  loadingVouchers: false,
  voucherStatus: {
    type: "",
    Class: "",
    Message: "",
  },
};

const voucherLists = {
  state,
  getters: {},
  mutations: {
    SET_voucher_TEMPLATES(state: { voucherLists: any }, data: any) {
      state.voucherLists = data;
    },
    LOADING_STATUS(state: { loadingVouchers: boolean }, data: any) {
      state.loadingVouchers = data;
    },
    SHOW_MESSAGE(state: { showMsgVouchers: boolean }, data: any) {
      state.showMsgVouchers = data;
    },
    CONTROL_STATUS(state: { voucherStatus: object }, data: any) {
      state.voucherStatus = data;
    },
  },
  actions: {
    getAllVouchers({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      return GET(`api/v1/vouchers`, {
        params: payload,
      })
        .then((response) => {
          commit("LOADING_STATUS", false);
          commit("SET_voucher_TEMPLATES", response.data.data);
        })
        .catch((error) => {});
    },
    clearVouchers({ commit }: any) {
      commit("SET_voucher_TEMPLATES", []);
    },
    deleteVoucher({ dispatch, commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      return DELETE(`api/v1/vouchers/${payload.voucherCampaignId}`, {
        data: payload,
      })
        .then((response) => {
          commit("LOADING_STATUS", false);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-success";
          model.Message = "The voucher has been deleted successfully.";
          commit("CONTROL_STATUS", model);
          commit("SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SHOW_MESSAGE", false);
          }, 4000);
          dispatch("getAllVouchers", {
            clubId: payload.clubId,
            limit: -1,
          });
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
          var model = new SnackBarModel();
          model.type = "error";
          model.Class = "status-error";
          model.Message =
            error?.response?.data?.Status?.Message || "Something went wrong.";
          commit("CONTROL_STATUS", model);
          commit("SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SHOW_MESSAGE", false);
          }, 4000);
        });
    },
    replicateVoucher({ dispatch, commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      return DELETE(
        `api/v1/voucher/clubs/${payload.id}/templates/${payload.templateId}`
      )
        .then((response) => {
          commit("LOADING_STATUS", false);
          var model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-success";
          model.Message = "The voucher has been replicated successfully.";
          commit("CONTROL_STATUS", model);
          commit("SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SHOW_MESSAGE", false);
          }, 2000);
          dispatch("getvoucherTemplates", payload);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
  },
};
export default voucherLists;
