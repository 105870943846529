<div class="left-sidebar">
  <AppLoader v-if="isMenuLoading" :type="'pulsing'"></AppLoader>
  <kbutton class="sidebar-open-close-btn top-button hvr-no-effect" v-if="resPonsiveSidebarOpen != 'noAction'"
    :fill-mode="'flat'" :method="sidebarToggleHandler" @click="sidebarToggleHandler" :class="''">
    <span class="sidebar-icon-open">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 5.25C2 4.83579 2.33579 4.5 2.75 4.5H17.25C17.6642 4.5 18 4.83579 18 5.25C18 5.66421 17.6642 6 17.25 6H2.75C2.33579 6 2 5.66421 2 5.25ZM2 10.25C2 9.83579 2.33579 9.5 2.75 9.5H17.25C17.6642 9.5 18 9.83579 18 10.25C18 10.6642 17.6642 11 17.25 11H2.75C2.33579 11 2 10.6642 2 10.25ZM2.75 14.5C2.33579 14.5 2 14.8358 2 15.25C2 15.6642 2.33579 16 2.75 16H17.25C17.6642 16 18 15.6642 18 15.25C18 14.8358 17.6642 14.5 17.25 14.5H2.75Z"
          fill="#6C707D" />
      </svg>
    </span>
    <span class="sidebar-icon-close">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.89705 4.05379L3.96967 3.96967C4.23594 3.7034 4.6526 3.6792 4.94621 3.89705L5.03033 3.96967L10 8.939L14.9697 3.96967C15.2359 3.7034 15.6526 3.6792 15.9462 3.89705L16.0303 3.96967C16.2966 4.23594 16.3208 4.6526 16.1029 4.94621L16.0303 5.03033L11.061 10L16.0303 14.9697C16.2966 15.2359 16.3208 15.6526 16.1029 15.9462L16.0303 16.0303C15.7641 16.2966 15.3474 16.3208 15.0538 16.1029L14.9697 16.0303L10 11.061L5.03033 16.0303C4.76406 16.2966 4.3474 16.3208 4.05379 16.1029L3.96967 16.0303C3.7034 15.7641 3.6792 15.3474 3.89705 15.0538L3.96967 14.9697L8.939 10L3.96967 5.03033C3.7034 4.76406 3.6792 4.3474 3.89705 4.05379L3.96967 3.96967L3.89705 4.05379Z"
          fill="#6C707D" />
      </svg>
    </span>
  </kbutton>

  <div class="full-content d-flex justify-content-between flex-column h-100">
    <div>
      <div class="sidebar-logo bg-blue-gradient">
        <a href="/"><img class="logo" src="../../../assets/images/logo-blue.png" alt="Logo" /></a>
      </div>


      <ul class="py-24 sidebar-navigation">
        <li v-for="(item, index) in menuWithPermission" :key="index">
          <kbutton v-if="item?.hasGetPermission && (item.text!='Digital Pass'||(item.text=='Digital Pass' && clubDetails.enablePass)) &&
                         (item.text!='Voucher'||(item.text=='Voucher' && clubDetails.enableVoucher)) && 
                         (item.text!='Email Campaign' ||(item.text=='Email Campaign' && clubDetails?.enableCampaign))"
            :class="['btn-hover-blue' ,'font-16', clickedComponentList[item.code] ? 'active' : '',]" :fill-mode="'flat'" @click="onPress(item)">

            <span class="sidebar-icon-box " v-if="item.text=='Dashboard'">
              <span class="cb-dashboard"></span>
            </span>

            <span class="sidebar-icon-box" v-if="item.text=='Members' && item?.hasGetPermission">
              <span class="cb-member"></span>
            </span>

            <span class="sidebar-icon-box" v-if="item.text=='Digital Pass' && item?.hasGetPermission">
              <span class="cb-digital-pass"></span>
            </span>

            <span class="sidebar-icon-box" v-if="item.text=='Email Campaign' && item?.hasGetPermission">
              <span class="cb-email-normal font-16"></span>
            </span>

            <span class="sidebar-icon-box" v-if="item.text=='Voucher' && item?.hasGetPermission">
              <span class="cb-gift-icon font-18"></span>
            </span>

            <span class="sidebar-icon-box right" v-if="item.text=='Digital Pass' && item?.hasGetPermission">
              <span class="cb-arrow-right font-11"></span>
            </span>

            <span class="sidebar-icon-box right" v-if="item.text=='Email Campaign' && item?.hasGetPermission">
              <span class="cb-arrow-right font-11"></span>
            </span>

            <span class="sidebar-icon-box right" v-if="item.text=='Voucher' && item?.hasGetPermission">
              <span class="cb-arrow-right font-11"></span>
            </span>

            <span class="sidebar-icon-box" v-if="item.text=='Email Log' && item?.hasGetPermission">
              <span class="cb-email-log">
                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0005 3.51126C19.9023 4.08885 20.5 5.09963 20.5 6.25V12.25C20.5 15.1495 18.1495 17.5 15.25 17.5H6.25C5.09963 17.5 4.08885 16.9023 3.51126 16.0005L15.25 16C17.3211 16 19 14.3211 19 12.25L19.0005 3.51126ZM3.25 0H14.75C16.483 0 17.8992 1.35645 17.9949 3.06558L18 3.25V11.75C18 13.483 16.6435 14.8992 14.9344 14.9949L14.75 15H3.25C1.51697 15 0.100754 13.6435 0.00514483 11.9344L0 11.75V3.25C0 1.51697 1.35645 0.100754 3.06558 0.0051446L3.25 0ZM16.5 4.899L9.34931 8.66369C9.16189 8.76233 8.94313 8.77642 8.74676 8.70596L8.65069 8.66369L1.5 4.9V11.75C1.5 12.6682 2.20711 13.4212 3.10647 13.4942L3.25 13.5H14.75C15.6682 13.5 16.4212 12.7929 16.4942 11.8935L16.5 11.75V4.899ZM14.75 1.5H3.25C2.33183 1.5 1.57881 2.20711 1.5058 3.10647L1.502 3.206L9 7.15246L16.4994 3.20483C16.4755 2.25921 15.7014 1.5 14.75 1.5Z" fill="#404A61"/>
                  </svg>                  
              </span>
            </span>

            <span class="sidebar-menu-text" v-if="item?.hasGetPermission">{{item.text}}</span>

          </kbutton>

        <li class="left-border" v-if="item.code=='digitalPass' && openDigitalPass && clubDetails.enablePass"
          v-for="(dpitem, index) in item.items" :key="index">

          <kbutton v-if="dpitem?.hasGetPermission" :fill-mode="'flat'" @click="onPress(dpitem)" :class="[
          'btn-hover-blue' ,
          'font-16',
          clickedComponentList[dpitem.code] ? 'active' : '']">
            <span class="sidebar-icon-box" v-if="dpitem.text=='All Digital Pass' && dpitem?.hasGetPermission">
              <span class="cb-ticket-horaizon font-14"></span>
            </span>
            <span class="sidebar-icon-box" v-if="dpitem.text=='Audience' && dpitem?.hasGetPermission">
              <span class="cb-audience font-18"></span>
            </span>
            <span class="sidebar-menu-text" v-if="dpitem?.hasGetPermission">{{dpitem.text}}</span>
            <span class="sidebar-icon-box right" v-if="dpitem.text=='Audience' && dpitem?.hasGetPermission">
              <span class="cb-arrow-right font-11"></span>
            </span>
          </kbutton>

        <li v-if="dpitem.code=='dpAudience' && openDPAudience && dpitem?.hasGetPermission"
          v-for="(dpChilditem, index) in dpitem.items">
          <kbutton v-if="dpChilditem?.hasGetPermission" :fill-mode="'flat'" @click="onPress(dpChilditem)" :class="[
           'btn-hover-blue font-16 btn-without-icon', clickedComponentList[dpChilditem.code] ? 'active' : '' , ]">
            <span class="sidebar-menu-text">{{dpChilditem.text}}</span>
          </kbutton>
        </li>
        </li>

        <!-- Don't remove comment part -->
        <li class="left-border" v-if="item.code=='emailCampaign' && openEmailCampaign && clubDetails?.enableCampaign"
          v-for="(campitem, index) in item.items" :key="index">

          <kbutton v-if="campitem?.hasGetPermission" :fill-mode="'flat'" @click="onPress(campitem)" :class="[
          'btn-hover-blue' ,
          'font-16',
          clickedComponentList[campitem.code] ? 'active' : '',]">
            <span class=" sidebar-icon-box" v-if="campitem.text=='Audience' && campitem?.hasGetPermission">
              <span class="cb-audience font-18"></span>
            </span>
            <span class="sidebar-icon-box" v-if="campitem.text=='All Campaign' && campitem?.hasGetPermission">
              <span class=" font-14">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.9068 5.62188C21.9686 5.8299 22 6.04578 22 6.2628V17.7382C22 18.9808 20.9926 19.9882 19.75 19.9882C19.5329 19.9882 19.317 19.9568 19.1089 19.8949L13.595 18.2553C12.9378 19.6003 11.5584 20.4989 10 20.4989C7.8578 20.4989 6.10892 18.815 6.0049 16.6986L6 16.4989L5.999 15.9982L3.60891 15.2875C2.65446 15.0038 2 14.1265 2 13.1308V10.8689C2 9.87308 2.65455 8.99573 3.60908 8.71208L19.1091 4.10601C20.3002 3.75204 21.5528 4.43072 21.9068 5.62188ZM7.499 16.4442L7.5 16.4989C7.5 17.8797 8.61929 18.9989 10 18.9989C10.8852 18.9989 11.6783 18.5347 12.1238 17.8195L7.499 16.4442ZM19.5364 5.54387L4.03636 10.1499C3.71818 10.2445 3.5 10.5369 3.5 10.8689V13.1308C3.5 13.4627 3.71815 13.7551 4.0363 13.8497L19.5363 18.4571C19.6057 18.4777 19.6776 18.4882 19.75 18.4882C20.1642 18.4882 20.5 18.1524 20.5 17.7382V6.2628C20.5 6.19046 20.4895 6.1185 20.4689 6.04916C20.3509 5.6521 19.9334 5.42588 19.5364 5.54387Z"
                    fill="#404A61" />
                </svg>
              </span>
            </span>
            <span class="sidebar-icon-box" v-if="campitem.text=='Flow Automation' && campitem?.hasGetPermission">
              <span class=" font-14">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4H18.75H5.25ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z"
                    fill="#404A61" />
                </svg>
              </span>
            </span>
            <span class="sidebar-menu-text" v-if="campitem?.hasGetPermission">{{campitem.text}}</span>
            <span class="sidebar-icon-box right" v-if="campitem.text=='Audience' && campitem?.hasGetPermission">
              <span class="cb-arrow-right font-11"></span>
            </span>
          </kbutton>

        <li v-if="campitem.code=='ecAudience' && campitem?.hasGetPermission && openECAudience"
          v-for="(campChildItem, index) in campitem.items">
          <kbutton v-if="campChildItem?.hasGetPermission" :fill-mode="'flat'" @click="onPress(campChildItem)" :class="[
          'btn-hover-blue' ,'font-16', 'btn-without-icon',clickedComponentList[campChildItem.code] ? 'active' : '',]">
            {{campChildItem.text}}</kbutton>
        </li>
        </li>

        <li class="left-border" v-if="item.code=='voucher' && openVoucher && clubDetails.enableVoucher"
          v-for="(vcItem, index) in item.items" :key="index">
          <kbutton v-if="vcItem?.hasGetPermission" :fill-mode="'flat'" @click="onPress(vcItem)" :class="[
          'btn-hover-blue' ,
          'font-16',
          clickedComponentList[vcItem.code] ? 'active' : '']">
            <span class="sidebar-icon-box" v-if="vcItem.text=='Audience' && vcItem?.hasGetPermission">
              <span class="cb-audience font-18"></span>
            </span>
            <span class="sidebar-icon-box" v-if="vcItem.text=='All Voucher' && vcItem?.hasGetPermission">
              <span class=" font-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.465" height="19.464" viewBox="0 0 19.465 19.464">
                  <path id="Path_1" data-name="Path 1"
                    d="M18.078,0a2.25,2.25,0,0,1,2.25,2.25V7.712a3.25,3.25,0,0,1-.952,2.3l-8.5,8.5a3.255,3.255,0,0,1-4.6,0L1.817,14.059a3.249,3.249,0,0,1,0-4.6l8.5-8.51A3.25,3.25,0,0,1,12.613,0Zm0,1.5H12.613a1.75,1.75,0,0,0-1.238.513L2.862,10.536A1.75,1.75,0,0,0,2.877,13l4.461,4.455a1.755,1.755,0,0,0,2.477,0l8.5-8.5a1.75,1.75,0,0,0,.512-1.237V2.25A.75.75,0,0,0,18.078,1.5Zm-2.75,2a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,15.328,3.5Z"
                    transform="translate(-0.863)" fill="#404a61" />
                </svg>
              </span>
            </span>
            <span class="sidebar-menu-text" v-if="vcItem?.hasGetPermission">{{vcItem.text}}</span>
            <span class="sidebar-icon-box right" v-if="vcItem.text=='Audience' && vcItem?.hasGetPermission">
              <span class="cb-arrow-right font-11"></span>
            </span>
          </kbutton>
        <li v-if="vcItem.code=='vAudience' && vcItem?.hasGetPermission && openVAudience"
          v-for="(vcChildItem, index) in vcItem.items">
          <kbutton v-if="vcChildItem?.hasGetPermission" :fill-mode="'flat'" @click="onPress(vcChildItem)" :class="[
          'btn-hover-blue font-16 sidebar-menu-text-btn',clickedComponentList[vcChildItem.code] ? 'active' : '']">
            {{vcChildItem.text}}</kbutton>
        </li>
        </li>

        </li>
      </ul>

    </div>

    <div class="author-info px-26 pb-22 d-flex align-items-end justify-content-between pt-3 border-top">
      <div class="left-content d-flex align-items-center justify-content-start max-w-100-33">
        <avatar v-if="user.userPhotoPath" :type="'image'" :rounded="'full'" :size="'medium'" :class="'mr-12'">
          <img :src="user.userPhotoPath" alt="Logo" />
        </avatar>
        <avatar v-if="!user.userPhotoPath" :rounded="'full'" :size="'medium'" :class="'mr-12'">
          {{GenerateNameInitials()}}
        </avatar>
        <div class="d-flex flex-column max-w-100-52">
          <h6 class="font-14 color-black-2 font-w-600 mb-0 l-height-19 ellipsis">
            {{user.firstName}} {{user.lastName}}
          </h6>
          <span class="font-12 color-light opacity-8 l-height-16 ellipsis">{{userType}}</span>
        </div>
      </div>
      <AppPopUp :buttonClass="'btn-clear'" :class="'popup-menu'" :items="profileDropdownitems" @dropId="getDropId"
        :icon="'more-horizontal'" :popUpSettings="popupSettings"></AppPopUp>
    </div>
  </div>
</div>