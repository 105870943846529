<template>
  <div class="loader-wrap">
    <Loader :theme-color="color" :size="size" :type="type" />
  </div>
</template>

<script>
import { Loader } from "@progress/kendo-vue-indicators";
const themeColors = ["tertiary"];

export default {
  components: {
    Loader,
  },
  props: {
    size: {
      type: String,
      default: "large",
    },
    type: {
      type: String,
      default: "converging-spinner",
    },
  },
  data() {
    return {
      themeColors,
    };
  },
};
</script>
<style type="text/css">

/* Old */

/* .loader-wrap {
  z-index: 99999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */


/* New */

/* .loader-wrap {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
} */

/* New No click */

.loader-wrap {
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9010;
  pointer-events: none;
}

</style>
