import { DELETE, GET, POST, PUT } from "@/service";

export const getCampaignOverallPerformanceService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-overall-performance`, {
    params: payload,
  });
};

export const getCampaignPerformanceOvertimeService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-performance-overtime`, {
    params: payload,
  });
};

export const getCampaignVariationService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-variation`, {
    params: payload,
  });
};

export const getCampaignClientDeviceService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-client_device`, {
    params: payload,
  });
};

export const getCampaignPerformanceByLocationService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-performance-by-location`, {
    params: payload,
  });
};

export const getCampaignPerformanceBenchmarkService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-performance-benchmarks`, {
    params: payload,
  });
};
