import { auth } from "./../../services/auth.service";
import { DELETE, GET, POST } from "@/service";
import axios from "axios";
import GetMembersModel from "@/models/member/getMembersModel";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { configuration } from "@/configurationProvider";
import { errorMessageHandler } from "../../utils/helper";

export type State = {
  columnList: any[];
  memberModalVisible: boolean;
  columnModifier: any[];
  members: any;
  items: any;
  importMembersResponse: any[];
  loader: any;
  searchQuery: any;
  showMsg: boolean;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
  loading: boolean;
  deleteModalVisible: boolean;
  actionModalVisible: boolean;
  fetchController: "notfetched";
  status: SnackBarModel;
  successStatus: SnackBarModel;
  errorStatus: SnackBarModel;
  isImported: boolean;
  addIndividualMemberModalVisibility: boolean;
  addIndividualMemberModalLoader: boolean;
  addMemberErrors: any;
  loading_delete: false;

  anyFilterExistInMembers: any;
  isColumnMetaLoading: boolean;
};
const state: State = {
  columnList: [],
  columnModifier: [],
  memberModalVisible: false,
  items: [],
  members: {},
  importMembersResponse: [],
  loader: false,
  searchQuery: "",
  loading: false,
  deleteModalVisible: false,
  actionModalVisible: false,
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  successStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  errorStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  showMsg: false,
  showErrorMsg: false,
  showSuccessMsg: false,
  isImported: true,
  fetchController: "notfetched",
  addIndividualMemberModalVisibility: false,
  addIndividualMemberModalLoader: false,
  addMemberErrors: [],
  loading_delete: false,
  anyFilterExistInMembers: false,
  isColumnMetaLoading: false,
};
const members = {
  state,
  getters: {
    getColumnList(state: { columnList: {} }) {
      return state.columnList;
    },
  },
  mutations: {
    SET_COLUMNS(state: { columnList: any }, data: any) {
      state.columnList = data;
    },
    SET_COLUMNSMODIFIER(state: { columnModifier: any }, data: any) {
      state.columnModifier = data;
    },
    IMPORT_MODAL_VISIBLE(state: { memberModalVisible: boolean }, data: any) {
      state.memberModalVisible = data;
    },
    SET_MEMBERS(state: { members: any }, data: any) {
      state.members = data;
    },
    SET_ITEMS(state: { items: any }, data: any) {
      state.items = data;
    },
    SET_IMPORT_RESPONSE(state: { importMembersResponse: any }, data: any) {
      state.importMembersResponse = data;
    },
    SET_IMPORT_LOADER(state: { loader: any }, data: any) {
      state.loader = data;
    },
    SET_SEARCH_QUERY(state: { searchQuery: any }, data: any) {
      state.searchQuery = data;
    },
    LOADING_STATUS_MEMBER(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    LOADING_STATUS_DELETE(state: { loading_delete: boolean }, data: any) {
      state.loading_delete = data;
    },
    DELETE_MEMBER_MODAL_VISIBLE(
      state: { deleteModalVisible: boolean },
      data: any
    ) {
      state.deleteModalVisible = data;
    },
    ACTION_MODAL_VISIBLE(state: { actionModalVisible: boolean }, data: any) {
      state.actionModalVisible = data;
    },
    SET_CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
    SET_SUCCESS_STATUS(state: { successStatus: object }, data: any) {
      state.successStatus = data;
    },
    SET_ERROR_STATUS(state: { errorStatus: object }, data: any) {
      state.errorStatus = data;
    },
    SET_SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    SET_SUCCESS_MESSAGE(state: { showSuccessMsg: boolean }, data: any) {
      state.showSuccessMsg = data;
    },
    SET_ERROR_MESSAGE(state: { showErrorMsg: boolean }, data: any) {
      state.showErrorMsg = data;
    },
    SET_IS_IMPORTED(state: { isImported: boolean }, data: any) {
      state.isImported = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },
    SET_ADD_INDIVIDUAL_MEMBER_MODAL_VISIBILITY(
      state: { addIndividualMemberModalVisibility: boolean },
      data: any
    ) {
      state.addIndividualMemberModalVisibility = data;
    },
    SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER(
      state: { addIndividualMemberModalLoader: boolean },
      data: any
    ) {
      state.addIndividualMemberModalLoader = data;
    },
    SET_ADD_MEMBER_ERRORS(state: { addMemberErrors: any }, data: any) {
      state.addMemberErrors = data;
    },
    SET_ANY_FILTER_EXIST_IN_MEMBERS(
      state: { anyFilterExistInMembers: any },
      data: any
    ) {
      state.anyFilterExistInMembers = data;
    },
    SET_IS_COLUMN_META_LOADING(state: { isColumnMetaLoading: boolean }, data: any) {
      state.isColumnMetaLoading = data;
    },
  },
  actions: {
    getColumns({ commit }: any, payload: any) {
      commit("SET_IS_COLUMN_META_LOADING", true);
      return GET(`api/v1/Document/${payload.documentId}/meta`, {
        params: payload,
      })
        .then((response) => {
          commit("SET_IS_COLUMN_META_LOADING", false);
          commit("SET_COLUMNS", response.data.columns);
          commit("SET_COLUMNSMODIFIER", response.data.columns);
        })
        .catch((error) => {
          commit("SET_IS_COLUMN_META_LOADING", false);
        });
    },
    importMembers({ commit, dispatch }: any, payload: any) {
      commit("SET_IMPORT_RESPONSE", 0);
      commit("LOADING_STATUS_MEMBER", true);
      return POST(`api/v1/member/${payload.clubId}/members/import/`, payload)
        .then((response) => {
          commit("LOADING_STATUS_MEMBER", false);
          commit("SET_IMPORT_LOADER", true);
          commit("SET_IMPORT_RESPONSE", response);
          commit("SET_IS_IMPORTED", true);

          let data = {
            clubId: payload.clubId,
            limit: 100,
          };
          dispatch("getMembers", data);
        })
        .catch((err) => {
          commit("SET_IS_IMPORTED", false);
          commit("LOADING_STATUS_MEMBER", false);
          var model = new SnackBarModel();
          model.type = "error";
          model.Class = "status-error";
          model.Message = "Error Occured";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 2000);
        });
    },
    addIndividualMember({ commit, dispatch }: any, payload: any) {
      return POST(`api/v1/member/${payload.clubId}/members`, payload)
        .then((response) => {
          commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER", false);
          let data = {
            clubId: payload.clubId,
            limit: 100,
          };
          dispatch("getMembers", data);

          const model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Member Added Successfully.";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_VISIBILITY", false);
        })
        .catch((error) => {
          commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER", false);
          commit("SET_ADD_MEMBER_ERRORS", error.response.data.errors);
        });
    },

    async downloadLog({ commit, dispatch }: any, clubId: any) {
      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/member/${clubId}/members/download-member-import-log`,
        method: "POST",
        responseType: "blob",
        headers: headers,
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        let filename = `Club or Association members error log.xlsx`;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getMembers({ commit }: any, payload: any) {
      commit("LOADING_STATUS_MEMBER", true);
      return GET(`api/v1/member/${payload.clubId}/members`, {
        params: payload,
      })
        .then((response) => {
          commit("LOADING_STATUS_MEMBER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_MEMBERS", response.data.data);
          commit("SET_ITEMS", response.data.data.items);
          // commit("SET_LOADER", false);
          if (payload.IsMember) {
            commit("SET_SEARCH_QUERY", payload.searchText);
          }
          if (payload.searchText || payload.subscriberStatusFilter) {
            commit("SET_ANY_FILTER_EXIST_IN_MEMBERS", true);
          }
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("LOADING_STATUS_MEMBER", false);
          commit("SET_LOADER", false);
        });
    },
    deleteMembers({ commit, dispatch }: any, payload: any) {
      commit("LOADING_STATUS_DELETE", true);
      return DELETE(`api/v1/member/${payload.clubId}/members/`, {
        data: payload,
      })
        .then((response) => {
          commit("LOADING_STATUS_DELETE", false);
          let data = {
            clubId: payload.clubId,
            limit: 100,
          };
          dispatch("getMembers", data);
          commit("DELETE_MEMBER_MODAL_VISIBLE", false);

          if (response.data?.data?.errorCount) {
            var model = new SnackBarModel();
            model.type = "error";
            model.Class = "status-error";
            model.Message = `Couldn't delete ${response.data?.data?.errorCount} members as they are in use.`;

            commit("SET_ERROR_STATUS", model);

            commit("SET_ERROR_MESSAGE", true);
            setTimeout(() => {
              commit("SET_ERROR_MESSAGE", false);
            }, 10000);
          }

          if (response.data?.data?.successCount) {
            var model = new SnackBarModel();
            model.type = "success";
            model.Class = "status-error";
            model.Message = `Successfully deleted ${response.data?.data?.successCount} members from the member's list.`;

            commit("SET_SUCCESS_STATUS", model);

            commit("SET_SUCCESS_MESSAGE", true);
            setTimeout(() => {
              commit("SET_SUCCESS_MESSAGE", false);
            }, 10000);
          }
          // dispatch("getMembers", data);
        })
        .catch((error) => {
          commit("DELETE_MEMBER_MODAL_VISIBLE", false);
          commit("LOADING_STATUS_DELETE", false);
          var model = new SnackBarModel();
          model.type = "error";
          model.Class = "status-error";
          model.Message =
            "Selected member cannot be deleted as they are in use.";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 10000);
          let data = {
            clubId: payload.clubId,
            limit: 100,
          };
          dispatch("getMembers", data);
        });
    },
    importCloseModal({ commit }: any) {
      commit("IMPORT_MODAL_VISIBLE", false);
    },
  },
};
export default members;
