import AddAudienceMemberModel from "@/models/audience/allAudience/AddAudienceMemberModel";
import AddConfigureFieldModel from "@/models/audience/allAudience/AddConfigureFieldModel";
import ExportAudienceMemberModel from "@/models/audience/allAudience/ExportAudienceMemberModel";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import ImportAudienceMemberModel from "@/models/audience/allAudience/ImportAudienceMemberModel";
import { DELETE, GET, POST, PUT } from "@/service";

export const getAudiences = (model: GetAudienceModel) => {
  return GET(`api/v1/Audience`, { params: model });
};

export const addAudienceMember = (model: AddAudienceMemberModel) => {
  return POST(`api/v1/Audience/addMember`, model);
};

export const importAudience = (model: ImportAudienceMemberModel) => {
  return POST(`api/v1/Audience/import`, model);
};

export const exportAudience = (model: ExportAudienceMemberModel) => {
  return POST(`api/v1/Audience/export`, model);
};

export const getAllFields = (clubId: any, entityType: any) => {
  return GET(
    `api/v1/Audience/fields?clubId=${clubId}&&entityType=${entityType}`
  );
};

export const getFieldById = (payload: any) => {
  return GET(`api/v1/Audience/fields/${payload.id}`, {
    params: payload,
  });
};

export const addNewField = (model: AddConfigureFieldModel) => {
  return POST(`api/v1/Audience/fields`, model);
};

export const addAudienceToList = (model: any) => {
  return POST(`api/v1/Audience/list/add-audience-to-list`, model);
};

export const deleteNewField = (payload: any) => {
  return DELETE(`api/v1/Audience/fields/${payload?.fieldId}`, {
    data: payload,
  });
};

export const removeAudienceFromList = (payload: any) => {
  return DELETE(`api/v1/Audience/list/remove-audience-from-list`, {
    data: payload,
  });
};

export const deleteAudience = (payload: any) => {
  return DELETE(`api/v1/Audience/bulk`, { data: payload });
};

export const bulkSave = (model: any) => {
  return POST(`api/v1/Audience/fields/editBulk`, model);
};

export const getDeleteWarning = (model: any) => {
  return GET(`api/v1/Audience/fields/delete-warning`, { params: model });
};

export const updateAudienceSubscriberStatusService = (payload: any) => {
  return PUT(`api/v1/audience/bulk/update-subscriber-status`, payload);
};

export const getAllAudienceColumnService = (payload: any) => {
  return GET(`api/v1/Audience/audience-render-fields`, {
    params: payload,
  });
};

export const updateAudienceColumnService = (payload: any) => {
  return PUT(
    `api/v1/Audience/${payload?.clubId}/update-status-audience-render-fields`,
    payload
  );
};
