<div>
  <DropDownButton
    :items="items"
    :icon="icon"
    @itemclick="onMenuSelect"
    :buttonClass="buttonClass"
    :popup-settings="popUpSettings"
    :disabled="disabled"
    @open="onOpen"
  />
</div>
