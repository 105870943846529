import { getTag, getTags } from "@/services/clubDetails/tag.service";

export type State = {
  isAddNewItemModal: boolean;
  isLoading: boolean;
  tags: any;
  item: any;
  isTagLoading: boolean;
  fetchController: "notfetched"; //Possible variable fetched , available , notavailable
};

const state: State = {
  isAddNewItemModal: false,
  isLoading: false,
  tags: [],
  item: {},
  isTagLoading: false,
  fetchController: "notfetched", //Possible variable fetched , available , notavailable
};

const tag = {
  state,
  getters: {
    tagDetails(state: { item: {} }) {
      return state.item;
    },
  },
  mutations: {
    SET_ALL_TAGS(state: { tags: any[] }, data: any) {
      state.tags = data;
    },

    SET_ONE_ITEM(state: { item: {} }, data: any) {
      state.item = data;
    },

    SET_ADD_NEW_ITEM_OPEN(state: { isAddNewFieldModal: boolean }, data: any) {
      state.isAddNewFieldModal = data;
    },

    SET_LOADER(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
    SET_TAG_LOADER(state: { isTagLoading: boolean }, data: any) {
      state.isTagLoading = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },
  },
  actions: {
    getTags({ commit }: any, payload: any) {
      commit("SET_LOADER", true);
      getTags(payload)
        .then((response) => {
          commit("SET_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_ALL_TAGS", response.data.data);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_LOADER", false);
        });
    },
    getTag({ commit, payload }: any, id: any) {
      commit("SET_TAG_LOADER", true);
      getTag(id)
        .then((response) => {
          commit("SET_TAG_LOADER", false);
          commit("SET_ONE_ITEM", response.data.data);
        })
        .catch((error) => {
          commit("SET_TAG_LOADER", false);
        });
    },
  },
};
export default tag;
