import { DELETE, GET, POST, PUT } from "@/service";

export const getRecipientsService = (payload: any) => {
  return GET(
    `api/v1/Pass/clubs/${payload.clubId}/templates/${payload.templateId}/members`,
    {
      params: payload,
    }
  );
};

export const getPassColumnsService = (payload: any) => {
  return GET(
    `api/v1/Document/${payload.documentId}/meta`,
    {
      params: payload,
    }
  );
};

export const getAudienceCustomFieldsService = (payload: any) => {
  return GET(`api/v1/Audience/fields/audience-mapping-dropdown`, {
    params: payload,
  });
};

export const getPassTypeAttributesService = (payload: any) => {
  return GET(
    `api/v1/Pass/clubs/${payload.clubId}/templates/${payload.templateId}/attributes`,
    payload
  );
};

export const importDigitalPassMappedDataService = (payload: any) => {
  return POST(`api/v1/Pass/import`, payload);
};

export const deleteRecipientsService = (payload: any) => {
  var payloadDelete = {
    clubId: payload.clubId,
    ids: payload.ids,
  };
  DELETE(`api/v1/Pass/delete/bulk/`, {
    data: payloadDelete,
  });
};

export const sentDigitalPassService = (payload: any) => {
  var payloadOriginal = {
    clubId: payload.clubId,
    ids: payload.ids,
  };
  return POST(`api/v1/pass/send-email/bulk`, payloadOriginal);
};

export const addListMembersToTemplateService = (payload: any) => {
  var payloadGetResipients = {
    clubId: payload.clubId,
    templateId: payload.templateId,
  };
  return POST(`api/v1/pass/add-template-members/lists`, payload);
};

export const addMembersToTemplateService = (payload: any) => {
  var payloadGetResipients = {
    clubId: payload.clubId,
    templateId: payload.templateId,
  };
  return POST(`api/v1/pass/add-template-members/`, payload);
};

export const getAttributeMapService = (payload: any) => {
  return GET(
    `api/v1/Pass/clubs/${payload.clubId}/templates/${payload.templateId}/attribute-map`,
    {
      params: payload,
    }
  );
};
