<template>
    <app-loader></app-loader>
</template>

<script>
import { auth } from '@/services/auth.service';
import AppLoader from '@/components/common/AppLoader/AppLoader.vue';

export default {
    components: {
        AppLoader
    },
    methods: {
        redirectLogout() {
            auth.logout();
            localStorage.removeItem("clubeez_session");
        },
    },
    mounted() {
        let queries = this.$route.query;
        sessionStorage.setItem('invitation', JSON.stringify(queries));
        this.redirectLogout();
    }

};
</script>

<style></style>