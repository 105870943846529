<div>
  <k-dialog v-if="handler" :class="cssClass" :title="title" @close="closeModal">
    <app-loader :size="size" :type="type" v-if="isLoading"></app-loader>
    <div class="modal-container">
      <slot name="content"></slot>
    </div>

    <dialog-actions-bar v-if="footer" :class="'justify-content-end modal-footer'">
      <slot name="footer"></slot>
    </dialog-actions-bar>
  </k-dialog>
</div>