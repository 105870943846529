import { userValidation } from "../utils/helper";

const digitalPass = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-allAudience",
    name: "DpAllAudience",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "All Audience",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/audience/allAudience/AllAudience.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-allAudience/audience-details/:memberId",
    name: "DpAudience",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "All Audience",
          to: {
            name: "DpAllAudience",
            props: true,
            query: {
              type: 1,
              key: 3,
            },
          },
        },
        {
          text: "Audience",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/members/memberDetails/memberDetails.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass",
    name: "DigitalPass",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Digital Pass" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/digitalPass/DigitalPass.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/create-pass",
    name: "CreatePass",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        { text: "Create" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/digitalPass/digitalPassCreate/DigitalPassCreate.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/edit-pass",
    name: "EditPass",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        { text: "Edit" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/digitalPass/digitalPassUpdate/DigitalPassUpdate.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/add-recipients",
    name: "AddRecipients",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        { text: "Recipients" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/digitalPass/addRecipients/AddRecipients.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/recipients",
    name: "ManageRecipientsTable",
    props: true,
    meta: {
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "clubname" },
        { name: "Digital Pass" },
        { name: "templateName" },
      ],
    },
    component: () =>
      import(
        "../components/clubDetails/digitalPass/manageRecipientsTable/ManageRecipientsTable.vue"
      ),
    meta: { requiresAuth: true },
  },
];

export default digitalPass;
