import SnackBarModel from "../../models/snackBar/snackBarModel";
import {
  getClubService,
  getClubsService,
} from "../../services/clubDetails/club.service";
import store from "../index";

let menueLoaded = false;

export type State = {
  clubs: any;
  club: any;
  errorMessages: any;
  status: SnackBarModel;
  loading: boolean;
  showMsg: boolean;
  modalVisible: boolean;
  assoModalVisible: boolean;
  deleteModalVisible: boolean;
  isClubDeleted: boolean;
  isClubAdded: boolean;
  isClubUpdated: boolean;
  clubName: string;
  updateErrors: any;
  fetchController: "notfetched"; //Possible variable fetched , available , notavailable
};

const state: State = {
  clubs: [],
  club: {},
  clubName: "None",
  errorMessages: [],
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  modalVisible: false,
  assoModalVisible: false,
  deleteModalVisible: false,
  showMsg: false,
  loading: false,
  isClubDeleted: false,
  isClubAdded: false,
  isClubUpdated: false,
  updateErrors: [],
  fetchController: "notfetched", //Possible variable fetched , available , notavailable
};

const club = {
  state,
  getters: {
    clubDetails(state: { club: {} }) {
      return state.club;
    },
    clubDetailsLoading(state: { loading: boolean }) {
      return state.loading;
    },
    addClubErrors(state: { errorMessages: [] }) {
      return state.errorMessages;
    },
    fetchController(state: { fetchController: string }) {
      return state.fetchController;
    },
  },
  mutations: {
    ADD_CLUB(state: { isClubAdded: boolean }, data: boolean) {
      state.isClubAdded = data;
    },
    SET_CLUBS(state: { clubs: any }, data: any) {
      state.clubs = data;
    },
    SET_CLUB(state: { club: {}; clubName: string }, data: any) {
      state.club = data;
    },
    EDIT_CLUB(state: { isClubUpdated: boolean }, data: any) {
      state.isClubUpdated = data;
    },
    REMOVE_CLUB(state: { isClubDeleted: boolean }, data: any) {
      state.isClubDeleted = data;
    },
    ADD_CLUB_ERRORMSG(state: { errorMsg: {} }, data: any) {
      state.errorMsg = data;
    },
    LOADING_STATUS(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    MODAL_VISIBLE(state: { modalVisible: boolean }, data: any) {
      state.modalVisible = data;
    },
    ASSO_MODAL_VISIBLE(state: { assoModalVisible: boolean }, data: any) {
      state.assoModalVisible = data;
    },
    DELETE_MODAL_VISIBLE(state: { deleteModalVisible: boolean }, data: any) {
      state.deleteModalVisible = data;
    },
    CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
    SET_ADD_ERRORS(state: { errorMessages: [] }, data: any) {
      state.errorMessages = data;
    },
    SET_UPDATE_ERRORS(state: { updateErrors: [] }, data: any) {
      state.updateErrors = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },
    SET_MENUE_LOADED(state: { menueLoaded: boolean }, data: any) {
      menueLoaded = data;
    },
  },
  actions: {
    getClubs({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      getClubsService(payload)
        .then((response) => {
          commit("SET_CLUBS", response.data.data.items);
          commit("LOADING_STATUS", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch(() => {
          commit("LOADING_STATUS", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          commit("SET_CLUBS", null);
        });
    },
    getClub({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      if (payload?.loadMenu && !menueLoaded) {
        store.commit("SET_MENU_LOADING", true);
      }
      getClubService(payload?.clubId)
        .then((response) => {
          if (payload?.loadMenu && !menueLoaded) {
            store.dispatch("getMenuWithPermission", {
              clubId: response.data.data.id,
              clubRoleId: response.data.data.roleId.toString(),
            });
            menueLoaded = true;
          }

          commit("SET_CLUB", response.data.data);
          commit("LOADING_STATUS", false);
        })
        .catch(() => {
          if (payload?.loadMenu && !menueLoaded) {
            store.commit("SET_MENU_LOADING", false);
          }
          commit("SET_CLUB", null);
        });
    },
    closeModal({ commit }: any) {
      commit("MODAL_VISIBLE", false);
    },
    assoCloseModal({ commit }: any) {
      commit("ASSO_MODAL_VISIBLE", false);
    },
    deleteCloseModal({ commit }: any) {
      commit("DELETE_MODAL_VISIBLE", false);
    },
  },
};
export default club;
