import { DELETE, GET, POST, PUT } from "@/service";

export const getFormsList = (payload: any) => {
  return GET(`api/v1/forms/get-all-forms`, {
    params: payload,
  });
};

export const getFormInfo = (payload: any) => {
  return GET(`api/v1/forms/get-forms/`, {
    params: payload,
  });
};

export const getCheckDuplicateForm = (payload: any) => {
  return GET(`api/v1/forms/check-duplicate-form`, {
    params: payload,
  });
};

export const saveForm = (payload: any) => {
  return POST(`api/v1/forms/save-forms`, payload);
};

export const updateForm = (payload: any) => {
  return PUT(`api/v1/forms/update-forms`, payload);
};

export const deleteForm = (payload: any) => {
  return DELETE(`api/v1/forms/delete-forms`, {
    data: payload,
  });
};

export const cloneForm = (payload: any) => {
  return POST(`api/v1/forms/clone-forms`, payload);
};

export const getFormCountries = (payload: any) => {
  return GET(`api/v1/club/${payload.clubId}/get-countries`);
};

export const getFormSettings = (payload: any) => {
  return GET(`api/v1/forms/get-form-settings`, {
    params: payload,
  });
};

export const updateFormSettings = (payload: any) => {
  return PUT(`api/v1/forms/update-form-settings`, payload);
};
