import SnackBarModel from "@/models/snackBar/snackBarModel";
import { getRoleListService } from "@/services/clubDetails/setting.service";
import { getMenuWithPermissionService } from "@/services/clubDetails/common.service";

export type State = {
  showMsg: boolean;
  status: SnackBarModel;
  loader: boolean;
  modalVisible: boolean;
  segmentModalVisibility: boolean;
  isPageNotFoundVisible: boolean;
  rolesList: any;
  isRoleListLoading: boolean;
  notifications: any;

  menuWithPermission: any;
  isMenuLoading: boolean;
  mainContainerWidth: Number;
};
const state: State = {
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  showMsg: false,
  loader: false,
  modalVisible: false,
  segmentModalVisibility: false,
  isPageNotFoundVisible: false,
  rolesList: [],
  isRoleListLoading: false,
  notifications: [],

  menuWithPermission: [],
  isMenuLoading: true,
  mainContainerWidth: 600,
};

const common = {
  state,
  getters: {},
  mutations: {
    SET_SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    SET_STATUS(state: { status: {} }, data: any) {
      state.status = data;
    },
    SET_LOADER(state: { loader: string }, data: any) {
      state.loader = data;
    },
    MODAL_VISIBLE_COMMON(state: { modalVisible: boolean }, data: any) {
      state.modalVisible = data;
    },
    SET_SEGMENT_MODAL_VISIBILITY(
      state: { segmentModalVisibility: boolean },
      data: any
    ) {
      state.segmentModalVisibility = data;
    },
    SET_PAGE_NOT_FOUND_VISIBILITY(
      state: { isPageNotFoundVisible: boolean },
      data: any
    ) {
      state.isPageNotFoundVisible = data;
    },
    SET_ROLES_LIST(state: { rolesList: any }, data: any) {
      state.rolesList = data;
    },
    SET_ROLE_LIST_LOADING(state: { isRoleListLoading: boolean }, data: any) {
      state.isRoleListLoading = data;
    },
    SHOW_NOTIFICATION(state: { notifications: any }, data: any) {
      state.notifications.push({
        message: data.message,
        type: data.type,
        id: Math.random().toString(36),
      });
    },
    SET_MENU_WITH_PERMISSION(state: { menuWithPermission: any }, data: any) {
      state.menuWithPermission = data;
    },
    SET_MENU_LOADING(state: { isMenuLoading: boolean }, data: any) {
      state.isMenuLoading = data;
    },
    SET_MAINCONTAINER_WIDTH(state: { mainContainerWidth: Number }, data: any) {
      state.mainContainerWidth = data;
    },
  },
  actions: {
    showNotification({ commit }: any, payload: SnackBarModel) {
      var model = new SnackBarModel();
      model.type = payload.type;
      model.Class = payload.Class;
      model.Message = payload.Message;
      commit("SET_SHOW_MESSAGE", true);
      commit("SET_STATUS", model);
      setTimeout(() => {
        commit("SET_SHOW_MESSAGE", false);
      }, 4000);
    },
    async getRolesList({ commit }: any, payload: any) {
      commit("SET_ROLE_LIST_LOADING", true);
      try {
        const response = await getRoleListService(payload);
        if (response) {
          commit("SET_ROLE_LIST_LOADING", false);
          response.data?.data?.unshift({
            id: 0,
            name: "Select Role",
          });
          commit("SET_ROLES_LIST", response.data?.data);
        }
      } catch (error) {
        commit("SET_ROLE_LIST_LOADING", false);
      }
    },
    async getMenuWithPermission({ commit }: any, payload: any) {
      commit("SET_MENU_LOADING", true);
      try {
        const response = await getMenuWithPermissionService(payload);
        if (response) {
          commit("SET_MENU_WITH_PERMISSION", response.data?.data);
          commit("SET_MENU_LOADING", false);
        }
      } catch (error) {
        commit("SET_MENU_LOADING", false);
      }
    },
  },
};
export default common;
