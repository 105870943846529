<template>
  <div class="center">
   <h1>Add Event</h1>
   <router-link to="/events"> Back</router-link>
   <h2>Total Event: {{totalEvent}}</h2>

   <form  @submit.prevent="addEvent">
      <div>
       <label>Id</label>
       <input type="text" v-model="event.id" />
      </div>
      <div>
       <label>Title</label>
       <input type="text" v-model="event.name" />
      </div>
      <div>
        <label>Description</label>
        <textarea v-model="event.description"></textarea>
      </div>
      <button type="submit">Publish</button>
   </form>

   <table border="1px">
      <tr>
        <td>Id</td>
        <td>Name</td>
        <td>Description</td>
        <td>Action</td>
      </tr>
      <tr v-for="(event, index) in eventList" :key="index">
        <td>{{event.id}}</td>
        <td>{{event.name}}</td>
        <td>{{event.description}}</td>
        <router-link :to="`/event/${event.id}`">
           <button>Details</button>
        </router-link>
        <router-link :to="`/event/${event.id}/update`">
           <button>Update</button>
        </router-link>
        <button @click="removeEvent(index)">Remove</button>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
      return {
          event:{
              id:'',
              name:'',
              description:''
          }
      }
  },
  computed:{
     eventList(){
         return this.$store.state.events.events
     },
     totalEvent(){
         return this.$store.getters.totalEvent
     }
  },
  methods: {
      addEvent(){
         this.$store.dispatch("addEvent", this.event)
         this.event = {
            id:'',
            name:'',
            description:''
         }
      },
      removeEvent(index){
         this.$store.dispatch("removeEvent", index)
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
center {
    justify-content:center;
    align-items:center;
    text-align:center;
}
</style>
