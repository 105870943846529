import SnackBarModel from "@/models/snackBar/snackBarModel";
import { getTimeZones } from "@/services/clubDetails/emailCampaign.service";
import {
  getEmailCampaignTemplateList,
  getEmailCampaignTemplateMacros,
  getEmailTemplateUploadedImagesService,
} from "@/services/clubDetails/emailTemplate.service";

export type State = {
  notificationStatus: SnackBarModel;
  showNotification: boolean;
  isTimeZoneLoading: boolean;
  timeZoneList: any;
  timeZoneTempList: any;

  emailCampaignTemplateListLoading: boolean;
  emailCampaignList: any;
  emailCampaignName: string;
  emailCampaignTemplateMacroLists: any;
  isEmailCampaignTemplateMacroListsLoading: boolean;

  flowPageSwitchActionName: string;
  flowPageSwitchNodeInfo: any;

  emailTemplateImages: any;
  defaultImageObject: any;
  emailTemplateImagesLoading: boolean;
};

const state: State = {
  notificationStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  showNotification: false,
  isTimeZoneLoading: false,
  timeZoneList: [],
  timeZoneTempList: [],

  emailCampaignTemplateListLoading: false,
  emailCampaignList: [],
  emailCampaignName: "",
  emailCampaignTemplateMacroLists: [],
  isEmailCampaignTemplateMacroListsLoading: false,

  flowPageSwitchActionName: "",
  flowPageSwitchNodeInfo: {},

  emailTemplateImages: [],
  defaultImageObject: {
    id: 0,
    name: "widget_image",
    render_component: "imageViewRender",
    controller_component: "imageController",
    icon_component: "imageIcon",
    type: "Image",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        type: "image",
        src: "",
        fileSize: 0,
        fileName: "",
        defaultSrc:
          "https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/1b9cfbd1-76e7-4e67-9f97-f7c488eb5d92_638048592605592111.jpg",
        altText: "",
        imgUrl: "",
        urlTarget: "_self",
        isWidthLock: false,
        isPaddingLock: false,
        isRestricted: false,
        segmentId: 0,

        selectedTag: { Text: "Select Tag", Value: "", Id: 0 },
        isIncludeCampaignId: false,
        selectedActionType: {
          id: 1,
          label: "Open Website",
          value: "openWebsite",
        },

        actionTypeOptions: [
          {
            id: 1,
            label: "Open Website",
            value: "openWebsite",
          },
          {
            id: 2,
            label: "Insert Tag",
            value: "insertTag",
          },
        ],

        img_width: {
          width: "100%",
        },

        urlTargetOptions: [
          {
            label: "Same Tab",
            value: "_self",
          },
          {
            label: "New Tab",
            value: "_blank",
          },
        ],
        alignOptions: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
        selected: false,
        isAutoWidthCheck: false,
        style: {
          // width: "100%",
          // height: "auto",
          display: "table",
          width: "auto",
          maxWidth: "100%",
        },
        borderStyles: {
          borderRadius: "0px 0px 0px 0px",
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          boxSizing: "border-box",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "center",
        },
      },
    ],
  },
  emailTemplateImagesLoading: false,
};

const commonEmailCampaign = {
  state,
  getters: {},
  mutations: {
    SET_NOTIFICATION_STATUS(
      state: { notificationStatus: SnackBarModel },
      data: SnackBarModel
    ) {
      state.notificationStatus = data;
    },

    SET_SHOW_NOTIFICATION(state: { showNotification: boolean }, data: boolean) {
      state.showNotification = data;
    },
    SET_TIME_ZONE_LOADING_STATUS(
      state: { isTimeZoneLoading: boolean },
      data: boolean
    ) {
      state.isTimeZoneLoading = data;
    },
    SET_TIME_ZONE_LIST(state: { timeZoneList: any }, data: any) {
      state.timeZoneList = data;
    },

    SET_TIME_TEMP_ZONE_LIST(state: { timeZoneTempList: any }, data: any) {
      state.timeZoneTempList = data;
    },

    SET_EMAIL_CAMPAIGN_TEMPLATE_LIST_LOADING_STATUS(
      state: { emailCampaignTemplateListLoading: boolean },
      data: boolean
    ) {
      state.emailCampaignTemplateListLoading = data;
    },

    SET_EMAIL_CAMPAIGN_LIST(state: { emailCampaignList: any }, data: any) {
      state.emailCampaignList = data;
    },
    SET_EMAIL_CAMPAIGN_NAME(
      state: { emailCampaignName: string },
      data: string
    ) {
      state.emailCampaignName = data;
    },
    SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS(
      state: { emailCampaignTemplateMacroLists: any },
      data: any
    ) {
      state.emailCampaignTemplateMacroLists = data;
    },
    SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS_LOADING_STATUS(
      state: { isEmailCampaignTemplateMacroListsLoading: boolean },
      data: boolean
    ) {
      state.isEmailCampaignTemplateMacroListsLoading = data;
    },

    SET_FLOW_PAGE_SWITCH_ACTION_NAME(
      state: { flowPageSwitchActionName: string },
      data: string
    ) {
      state.flowPageSwitchActionName = data;
    },

    SET_FLOW_PAGE_SWITCH_NODE_INFO(
      state: { flowPageSwitchNodeInfo: any },
      data: any
    ) {
      state.flowPageSwitchNodeInfo = data;
    },

    SET_EMAIL_TEMPLATE_IMAGES(state: any, data: any) {
      if (data != null) {
        let modifiedDataArray: any[] = [];

        data.map((item: any) => {
          state.defaultImageObject.propertiesArray[0].src =
            item?.fileInfo?.url ||
            "https://images.pexels.com/photos/5500826/pexels-photo-5500826.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
          state.defaultImageObject.id = item?.fileInfo?.id;
          modifiedDataArray.push(structuredClone(state.defaultImageObject));
        });

        state.emailTemplateImages = [
          ...state.emailTemplateImages,
          ...modifiedDataArray,
        ];
      } else {
        state.emailTemplateImages = [];
      }
    },

    SET_EMAIL_TEMPLATE_IMAGES_LOADING_STATUS(
      state: { emailTemplateImagesLoading: boolean },
      data: boolean
    ) {
      state.emailTemplateImagesLoading = data;
    },
  },
  actions: {
    getTimeZonesList({ commit }: any, payload: any) {
      commit("SET_TIME_ZONE_LOADING_STATUS", true);
      commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER", true);
      getTimeZones(payload)
        .then((response) => {
          commit("SET_TIME_ZONE_LIST", response.data);
          commit("SET_TIME_TEMP_ZONE_LIST", response.data);
          commit("SET_TIME_ZONE_LOADING_STATUS", false);
          commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER", false);
        })
        .catch(() => {
          commit("SET_TIME_ZONE_LOADING_STATUS", false);
          commit("SET_ADD_INDIVIDUAL_MEMBER_MODAL_LOADER", false);
        });
    },

    getEmailCampaignTemplates({ commit }: any, payload: any) {
      commit("SET_EMAIL_CAMPAIGN_TEMPLATE_LIST_LOADING_STATUS", true);
      getEmailCampaignTemplateList(payload)
        .then((response) => {
          commit("SET_EMAIL_CAMPAIGN_LIST", response?.data?.data?.items);
          commit("SET_EMAIL_CAMPAIGN_TEMPLATE_LIST_LOADING_STATUS", false);
        })
        .catch(() => {
          commit("SET_EMAIL_CAMPAIGN_TEMPLATE_LIST_LOADING_STATUS", false);
        });
    },
    getMacrosList({ commit }: any, payload: any) {
      commit("SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS_LOADING_STATUS", true);
      getEmailCampaignTemplateMacros(payload)
        .then((response) => {
          commit(
            "SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS_LOADING_STATUS",
            false
          );
          commit(
            "SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS",
            response?.data?.data
          );
        })
        .catch(() => {
          commit(
            "SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS_LOADING_STATUS",
            false
          );
          commit("SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS", []);
        });
    },

    getEmailCampaignTemplateImages({ commit }: any, payload: any) {
      commit("SET_EMAIL_TEMPLATE_IMAGES_LOADING_STATUS", true);
      const { beforeActionName, ...othersPayload } = payload;
      getEmailTemplateUploadedImagesService(othersPayload)
        .then((response) => {
          if (payload?.beforeActionName === "delete") {
            commit("SET_EMAIL_TEMPLATE_IMAGES", null);
          }
          setTimeout(() => {
            commit("SET_EMAIL_TEMPLATE_IMAGES", response?.data?.data);
          }, 10);

          commit("SET_EMAIL_TEMPLATE_IMAGES_LOADING_STATUS", false);
        })
        .catch(() => {
          commit("SET_EMAIL_TEMPLATE_IMAGES_LOADING_STATUS", false);
        });
    },

    setFlowPageSwitchActionName({ commit }: any, payload: any) {
      commit("SET_FLOW_PAGE_SWITCH_ACTION_NAME", payload);
    },

    setFlowPageSwitchNodeInfo({ commit }: any, payload: any) {
      commit("SET_FLOW_PAGE_SWITCH_NODE_INFO", payload);
    },
  },
};
export default commonEmailCampaign;
