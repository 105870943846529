import { userValidation } from "../utils/helper";

const audience = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-allAudience",
    name: "voucherAllAudience",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "All Audience",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/audience/allAudience/AllAudience.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-allAudience/audience-details/:memberId",
    name: "voucherAudience",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "All Audience",
          to: {
            name: "voucherAllAudience",
            props: true,
            query: {
              type: 2,
              key: 1,
            },
          },
        },
        {
          text: "Audience",
        },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/clubDetails/members/memberDetails/memberDetails.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-configurefields",
    name: "voucherConfigureFields",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "All Audience",
          to: {
            name: "voucherAllAudience",
            props: true,
            params: true,
          },
        },
        {
          text: "Configure Fields",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/audience/allAudience/configureFields/ConfigureFields.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-lists",
    name: "voucherLists",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/lists/Lists.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "Lists",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-lists/:listId",
    name: "voucherSubscribers Lists",
    props: true,
    params: true,
    component: () =>
    import(
      "../components/clubDetails/audience/lists/listWithTabs.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "Lists",
          to: {
            name: "voucherLists",
          },
        },
        {
          text: "listName",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-segments",
    name: "voucherSegments",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/segments/Segments.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "Segments",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-segment/create",
    name: "voucherSegmentCreate",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/segments/CreateEditSegment/CreateUpdateSegment.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "Segments",
          to: {
            name: "voucherSegments",
            props: true,
          },
        },
        {
          text: "Create",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/voucher-tags",
    name: "voucherTags",
    props: true,
    params: true,
    component: () => import("../components/clubDetails/audience/tags/Tags.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "Tags",
        },
      ],
      requiresAuth: true,
    },
  },
];

export default audience;
