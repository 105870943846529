<template>
  <div class="center">
    <h1>Update Event</h1>
    <router-link to="/events"> Back</router-link>
    <form @submit.prevent="updateEvent">
      <div>
        <label>Id</label>
        <input type="text" v-model="event.id" />
      </div>
      <div>
        <label>Title</label>
        <input type="text" v-model="event.name" />
      </div>
      <div>
        <label>Description</label>
        <textarea v-model="event.description"></textarea>
      </div>
      <button type="submit">Publish</button>
    </form>
    <h1>{{ event.name }}</h1>
    <p>{{ event.description }}</p>

  </div>
</template>

<script>
export default {
  data() {
    return {
      event: {
        id: '',
        name: '',
        description: ''
      }
    }
  },
  computed: {
    eventList() {
      return this.$store.state.events.events
    },
    event() {
      return this.$store.state.events.events.find(x => x.id === this.$route.params.id)
    }
  },
  methods: {
    updateEvent() {
      this.$store.dispatch("updateEvent", this.event)
      this.event = {
        id: '',
        name: '',
        description: ''
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
