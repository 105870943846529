import { configuration } from "@/configurationProvider";
import { DELETE, GET, POST } from "@/service";
import axios from "axios";

export type State = {
  passList: any[];
  pass: any;
  passData: object;
  templateList: any[];
  deleteModalVisible: boolean;
};

const state: State = {
  passList: [],
  passData: {},
  pass: {},
  templateList: [],
  deleteModalVisible: false,
};

const digitalPass = {
  state,
  getters: {},
  mutations: {
    LOADING_STATUS(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    IMPORT_DONE(state: any, data: any) {},
    SET_PASS_LIST(state: { passList: any }, data: any) {
      state.passList = data;
    },
    SET_PASS_DATA(state: { passData: any }, data: any) {
      state.passData = data;
    },
    SET_TEMPLATE_LIST(state: { templateList: any }, data: any) {
      state.templateList = data;
    },
    DELETE_MODAL_VISIBLE(state: { deleteModalVisible: boolean }, data: any) {
      state.deleteModalVisible = data;
    },
  },
  actions: {
    importMember({ commit, dispatch }: any, payload: any) {
      commit("LOADING_STATUS", true);
      POST(`api/v1/Pass/create`, payload)
        .then((response) => {
          let data = {
            id: payload.clubId,
          };
          commit("IMPORT_DONE", response);
          dispatch("getPassList", data);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
    getPassList({ commit }: any, payload: any) {
      return POST(`api/v1/Pass/values/${payload.id}`, {
        params: payload,
      })
        .then((response) => {
          commit("SET_PASS_DATA", response.data.data);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
    exportList({ commit }: any, clubId: any) {
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Pass/export/${clubId}`,
        method: "POST",
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {});
    },
    sentPass({ commit }: any, payload: any) {
      POST(`api/v1/Pass/send-email`, payload)
        .then((response) => {})
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
    deletePass({ commit, dispatch }: any, payload: any) {
      DELETE(`api/v1/Pass/delete`, {
        data: payload,
      })
        .then((response) => {
          let value = {
            id: payload.clubId,
          };
          dispatch("getPassList", value);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
    deleteDocuments({ commit, dispatch }: any, payload: any) {
      DELETE(`api/v1/Document/` + payload)
        .then((response) => {})
        .catch((error) => {});
    },
    getTemplateList({ commit }: any, payload: any) {
      return GET(`api/v1/pass/${payload.id}`, {
        params: payload,
      })
        .then((response) => {
          commit("SET_TEMPLATE_LIST", response.data.data);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
    deleteModalToggle({ commit }: any) {
      commit("DELETE_MODAL_VISIBLE", false);
    },
  },
};
export default digitalPass;
