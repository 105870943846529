import { DELETE, GET, POST, PUT } from "@/service";

export const getAudienceOverallPerformanceService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-overall-performance`, {
    params: payload,
  });
};

export const getAudienceCampaignService = (payload: any) => {
  return GET(`api/v1/campaign/get-campaign-list`, {
    params: payload,
  });
};

export const getAudienceOverviewService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-audience-overview`, {
    params: payload,
  });
};

export const getAudiencePerformanceBenchmarkService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-performance-benchmarks`, {
    params: payload,
  });
};

export const getAudiencePerformanceByLocationService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-performance-by-location`, {
    params: payload,
  });
};

export const getAudienceClientDeviceService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-client-devices`, {
    params: payload,
  });
};

export const getAudienceVariationService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-audience-overview`, {
    params: payload,
  });
};
export const getAllAudienceColumnService = (payload: any) => {
  return GET(`api/v1/Audience/analytics/get-subscriber-sources`, {
    params: payload,
  });
};


// export const getEmailCampaignList = (payload: any) => {
//   return GET(`api/v1/Audience/get-Audience-list`, {
//     params: payload,
//   });
// };






