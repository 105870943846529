<template>
  <div class="center">
    <h1>Details Event</h1>
    <router-link to="/events"> Back</router-link>
    <h1>{{ event.name }}</h1>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    event() {
      return this.$store.state.events.events.find(event => event.id === this.$route.params.id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
