import { userValidation } from "../utils/helper";

const campaignAudience = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-lists",
    name: "EcLists",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/lists/Lists.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Lists",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-lists/:listId",
    name: "ECSubscribersLists",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/lists/listWithTabs.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Lists",
          to: {
            name: "EcLists",
          },
        },
        {
          text: "listName",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-lists/:listId/analytics",
    name: "listAndSegmentAnalytics",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/common/listAndSegmentAnalytics.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Lists",
          to: {
            name: "EcLists",
          },
        },
        {
          text: "listName",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-segments",
    name: "EcSegments",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/segments/Segments.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Segments",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-segments/:segmentId",
    name: "EcSegmentDetails",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/lists/listWithTabs.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Segments",
          to: {
            name: "EcSegments",
          },
        },
        {
          text: "segmentName",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-segment/create",
    name: "EcSegmentCreate",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/segments/CreateEditSegment/CreateUpdateSegment.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Segments",
          to: {
            name: "EcSegments",
            props: true,
          },
        },
        {
          text: "Create",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-segment/edit",
    name: "EcSegmentEdit",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/segments/CreateEditSegment/CreateUpdateSegment.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Segments",
          to: {
            name: "EcSegments",
            props: true,
          },
        },
        {
          text: "Edit",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-tags",
    name: "EcTags",
    props: true,
    params: true,
    component: () => import("../components/clubDetails/audience/tags/Tags.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "Tags",
        },
      ],
      requiresAuth: true,
    },
  },
];

export default campaignAudience;
