import { DELETE, GET, POST, PUT } from "@/service";

export const getTimeZones = (payload:any) => {
  return GET(`api/v1/System/time-zone?exceptId=${payload||0}`);
};

export const getEmailCampaignDetails = (payload: any) => {
  return GET(`api/v1/campaign/get-campaign`, {
    params: payload,
  });
};

export const getEmailCampaignList = (payload: any) => {
  return GET(`api/v1/campaign/get-campaign-list`, {
    params: payload,
  });
};

export const createEmailCampaign = (payload: any) => {
  return POST(`api/v1/campaign/add-campaign`, payload);
};

export const updateArchiveCampaign = (payload: any) => {
  return PUT(`api/v1/Campaign/archive-campaign`, payload);
};

export const updateEmailCampaign = (payload: any) => {
  return PUT(`api/v1/campaign/update-campaign`, payload);
};

export const deleteEmailCampaign = (payload: any) => {
  return DELETE(`api/v1/campaign/delete-campaign`, {
    data: payload,
  });
};

export const updateEmailCampaignStatus = (payload: any) => {
  return PUT(`api/v1/campaign/update-campaign-status`, payload);
};

export const cloneEmailCampaign = (payload: any) => {
  return POST(`api/v1/campaign/clone-campaign`, payload);
};

export const getSenderInfoService = (payload: any) => {
  return GET(`api/v1/Campaign/get-sender-info`, {
    params: payload,
  });
};

// campaign UTM
export const getUTMFieldsService = (payload: any) => {
  return GET(`api/v1/Campaign/get-utm-fields`, {
    params: payload,
  });
};

export const getUTMFieldValuesService = (payload: any) => {
  return GET(`api/v1/Campaign/get-utm-field-values`, {
    params: payload,
  });
};

// Email Campaign Analytics

export const getEmailCampaignAnalytics = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-overview`, { params: payload });
};

export const getEmailCampaignRecipientActivityService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-recipient-activity`, {
    params: payload,
  });
};

export const resendCampaignService = (payload: any) => {
  return POST(
    `api/v1/Campaign/resend-campaign?CampaignMemberId=${payload?.campaignMemberId}`,
    payload
  );
};

export const getEmailCampaignLinkActivityService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-link-activity`, {
    params: payload,
  });
};

export const getEmailCampaignLinkActivitySummeryService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-link-summary`, {
    params: payload,
  });
};

export const checkSenderDomainService = (payload: any) => {
  return GET(
    `api/v1/Club/${payload?.clubId}/check-sender-domain?domainName=${payload?.domainName}`,
    {
      params: payload,
    }
  );
};

export const addSenderDomainService = (payload: any) => {
  return POST(`api/v1/Club/add-sender-domain`, payload);
};

export const verifySenderDomainService = (payload: any) => {
  return POST(`api/v1/Club/verify-sender-domain`, payload);
};

export const verifyCheckCampaignService = (payload: any) => {
  return GET(`api/v1/Campaign/check-campaign`, {
    params: payload,
  });
};

//Email Action Trigger

export const getCampaignUrlServices = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-clickeable-url-link`, {
    params: payload,
  });
};

// Multivariant Result

export const getEmailCampaignMultivariantResultService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-multivariant`, {
    params: payload,
  });
};

export const getEmailCampaignMultivariantWinnerService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-multivariant-winner`, {
    params: payload,
  });
};

export const updateMultivariantWinnerService = (payload: any) => {
  return PUT(`api/v1/Campaign/update-winner`, payload);
};

//Audience Breakdown

export const getAudienceBreakDownListService = (payload: any) => {
  return GET(`api/v1/Campaign/get-campaign-audience-breakdown`, {
    // return GET(`api/v1/Campaign/get-campaign-recipient-activity`, {
    params: payload,
  });
};
