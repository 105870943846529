import { configuration } from "@/configurationProvider";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { getVoucherReportService } from "@/services/clubDetails/voucherReports.service";
import axios from "axios";
import { auth } from "../../services/auth.service";

export type State = {
  setReportsData: any;
  searchQuery: any;
  isAdvanceFiltering: boolean;
  status: SnackBarModel;
  loading: boolean;
  showMsg: boolean;
  successStatus: object;
  errorStatus: object;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
};
const state: State = {
  setReportsData: {},
  searchQuery: "",
  isAdvanceFiltering: false,
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  showMsg: false,
  loading: false,
  successStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  errorStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  showErrorMsg: false,
  showSuccessMsg: false,
};
const voucherReports = {
  state,
  getters: {},
  mutations: {
    SET_REPORTS(state: { setReportsData: any }, data: any) {
      state.setReportsData = data;
    },
    LOADING_STATUS(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    SET_SEARCH_QUERY(state: { searchQuery: any }, data: any) {
      state.searchQuery = data;
    },
    SET_IS_ADVANCE_FILTERING_EXIST(
      state: { isAdvanceFiltering: any },
      data: any
    ) {
      state.isAdvanceFiltering = data;
    },
    SET_SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    SET_CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
    SET_SUCCESS_STATUS(state: { successStatus: object }, data: any) {
      state.successStatus = data;
    },
    SET_ERROR_STATUS(state: { errorStatus: object }, data: any) {
      state.errorStatus = data;
    },
    SET_SUCCESS_MESSAGE_ISSHOW(state: { showSuccessMsg: boolean }, data: any) {
      state.showSuccessMsg = data;
    },
    SET_ERROR_MESSAGE_ISSHOW(state: { showErrorMsg: boolean }, data: any) {
      state.showErrorMsg = data;
    },
  },
  actions: {
    getVoucherReports({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      getVoucherReportService(payload)
        .then((response) => {
          commit("SET_REPORTS", response.data.data);
          commit("LOADING_STATUS", false);
          commit("SET_SEARCH_QUERY", payload.searchText);
          if (payload.redeemDateFilterType || payload.redeemAmountFilterType) {
            commit("SET_IS_ADVANCE_FILTERING_EXIST", true);
          } else {
            commit("SET_IS_ADVANCE_FILTERING_EXIST", false);
          }
        })
        .catch((err) => {
          commit("LOADING_STATUS", false);
          commit("SET_REPORTS", []);
          if (payload.redeemDateFilterType || payload.redeemAmountFilterType) {
            commit("SET_IS_ADVANCE_FILTERING_EXIST", true);
          } else {
            commit("SET_IS_ADVANCE_FILTERING_EXIST", false);
          }
        });
    },
    clearVoucherReports({ commit }: any) {
      commit("SET_REPORTS", []);
    },
    async voucherReportExport({ commit }: any, payloadExport: any) {
      commit("LOADING_STATUS", true);

      const { name, ...other } = payloadExport;
      const payload = other;

      let token = null;

      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Vouchers/reports/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      }).then((response) => {
        commit("LOADING_STATUS", false);
        if (response?.data?.type) {
          const model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Report Downloaded Successfully";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 2000);
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = `${name} report.xlsx`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        } else {
          const model = new SnackBarModel();
          model.type = "warning";
          model.Class = "status-error";
          model.Message = "No reports to download.";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 2000);
        }
      });
      // .catch((error) => {
      //   commit("LOADING_STATUS", false);
      //   commit("SET_SHOW_MESSAGE", true);
      //   const model = new SnackBarModel();
      //   model.type = "error";
      //   model.Class = "status-error";
      //   model.Message = "No reports to download.";
      //   commit("SET_CONTROL_STATUS", model);
      //   setTimeout(() => {
      //     commit("SET_SHOW_MESSAGE", false);
      //   }, 2000);
      // });
    },
  },
};
export default voucherReports;
