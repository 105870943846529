import { DELETE, GET, POST, PUT } from "@/service";

export const getRoleListService = (payload: any) => {
  return GET(`api/v1/club/${payload.clubId}/role`);
};

export const addRoleService = (payload: any) => {
  return POST(`api/v1/club/${payload.clubId}/role`, payload);
};

export const inviteRoleMembersService = (payload: any) => {
  return POST(`api/v1/club/${payload.clubId}/invite-role-members`, payload);
};

export const deleteUserService = (payload: any) => {
  return DELETE(
    `api/v1/Club/${payload.clubId}/role-members/${payload.clubSystemUserId}`,
    payload
  );
};

export const userResendInvitationService = (payload: any) => {
  return PUT(`api/v1/Club/${payload.clubId}/resend-invite`, payload);
};

export const userCancelInvitationService = (payload: any) => {
  return PUT(`api/v1/Club/${payload.clubId}/cancel-invite`, payload);
}

export const userChangeRoleService = (payload: any) => {
  return PUT(`api/v1/Club/${payload.clubId}/role-members/update-role`, payload);
};

export const userChangeStatusService = (payload: any) => {
  return PUT(`api/v1/Club/${payload.clubId}/user-status`, payload);
};

export const getRolePermissionService = (payload: any) => {
  return GET(
    `api/v1/club/get-role-permission?ClubId=${payload.clubId}&ClubRoleId=${payload.clubRoleId}`
  );
};

export const updateRolePermissionService = (payload: any) => {
  return PUT(`api/v1/Club/${payload.clubId}/role-permission/update`, payload);
};

export const updateRoleStatusService = (payload: any) => {
  return PUT(`api/v1/Club/${payload.clubId}/update-role`, payload);
};

export const createRolePermissionService = (payload: any) => {
  return POST(`api/v1/Club/${payload.clubId}/role-permission/create`, payload);
};

export const deleteRoleService = (payload: any) => {
  return DELETE(`api/v1/Club/${payload.clubId}/delete-role`, {
    data: payload,
  });
};
