import {
  getListById,
  getLists,
  getPremiumSubscriber,
} from "@/services/clubDetails/list.service";

export type State = {
  isAddNewItemModal: boolean;
  isLoading: boolean;
  lists: any;
  premiumLists: any;
  item: any;
  isListLoading: boolean;
  fetchController: "notfetched"; //Possible variable fetched , available , notavailable
};

const state: State = {
  isAddNewItemModal: false,
  isLoading: false,
  lists: [],
  premiumLists: [],
  item: {},
  isListLoading: false,
  fetchController: "notfetched", //Possible variable fetched , available , notavailable
};

const list = {
  state,
  getters: {
    listDetails(state: { item: {} }) {
      return state.item;
    },
  },
  mutations: {
    SET_ALL_LIST(state: { lists: any[] }, data: any) {
      state.lists = data;
    },
    SET_ALL_PREMIUM_LIST(state: { premiumLists: any[] }, data: any) {
      state.premiumLists = data;
    },
    SET_ONE_ITEM(state: { item: {} }, data: any) {
      state.item = data;
    },
    SET_ADD_NEW_ITEM_OPEN(state: { isAddNewFieldModal: boolean }, data: any) {
      state.isAddNewFieldModal = data;
    },
    SET_LOADER(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
    SET_LIST_LOADER(state: { isListLoading: boolean }, data: any) {
      state.isListLoading = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },

  },
  actions: {
    getLists({ commit }: any, payload: any) {
      commit("SET_LOADER", true);

      // var payload = {
      //   clubId: model.clubId,
      //   entityType: model.entityType,
      //   searchQuery: model.searchQuery,
      //   limit: model.limit || 1000,
      //   exceptInactiveLists: model.exceptInactiveLists,
      //   offset: 0,
      // };

      getLists(payload)
        .then((response) => {
          // response.data.data.items=[]
          commit("SET_LOADER", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_ALL_LIST", response.data.data);
          if (response?.data?.data?.items?.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_LOADER", false);
        });
    },

    getList({ commit, payload }: any, model: any) {
      commit("SET_LIST_LOADER", true);
      getListById(model)
        .then((response) => {
          commit("SET_LIST_LOADER", false);
          commit("SET_ONE_ITEM", response.data.data);
        })
        .catch((error) => {
          commit("SET_LIST_LOADER", false);
        });
    },

    getPremiumSubscriberList({ commit, payload }: any, model: any) {
      commit("SET_LOADER", true);
      commit("SET_LIST_LOADER", true);
      getPremiumSubscriber(model)
        .then((response) => {
          commit("SET_ALL_PREMIUM_LIST", response.data.data);
          commit("SET_LOADER", false);
          commit("SET_LIST_LOADER", false);
          
        })
        .catch((error) => {
          commit("SET_LOADER", false);
          commit("SET_LIST_LOADER", false);
        });
    },
  },
};
export default list;
