import {
  getBasicCampaignListService,
  getMetricsTypeListService,
  getProfileFieldsService,
} from "@/services/clubDetails/flowAutomation.service";

export type State = {
  flowBuilderAccordions: Object;
  flowAutomationDrawers: Object;
  flowInfoForSendEmail: Object;

  metricsTypeList: [];
  isMetricsTypeListLoading: boolean;
  CustomEventTypeId: number;
  notificationObject: Object;

  basicEmailCampaignList: [];
  flowAnalyticsModalVisibility: boolean;
  selectedFlowElement: Object;
  isInEditMode: boolean;

  dateAttributeFields: [];
  isDateAttributeFieldsLoading: boolean;

  profileUpdateFields: [];
  isProfileUpdateFieldsLoading: boolean;
};

const state: State = {
  flowBuilderAccordions: {
    triggerAccordionVisibility: true,
    actionAccordionVisibility: false,
    processAccordionVisibility: false,
  },
  flowAutomationDrawers: {},
  flowInfoForSendEmail: {
    flowName: "",
    flowType: "",
  },

  metricsTypeList: [],
  isMetricsTypeListLoading: false,
  CustomEventTypeId: 0,

  notificationObject: {
    notifyType: "",
    notifyMessage: "",
    isNotify: false,
  },

  basicEmailCampaignList: [],
  flowAnalyticsModalVisibility: false,
  selectedFlowElement: {},
  isInEditMode: false,

  dateAttributeFields: [],
  isDateAttributeFieldsLoading: false,

  profileUpdateFields: [],
  isProfileUpdateFieldsLoading: false,
};

const flowAutomationStore = {
  state,
  getters: {},
  mutations: {
    ADD_FLOW_AUTOMATION_DRAWER(
      state: { flowAutomationDrawers: any },
      data: any
    ) {
      state.flowAutomationDrawers[data.drawerName] = data.drawerStatus;
    },
    UPDATE_FLOW_AUTOMATION_DRAWER(
      state: { flowAutomationDrawers: any },
      data: any
    ) {
      if (Object.keys(state.flowAutomationDrawers).length > 0) {
        Object.keys(state.flowAutomationDrawers).forEach((key) => {
          state.flowAutomationDrawers[key] = false;
        });
      }
      state.flowAutomationDrawers[data.drawerName] = data.drawerStatus;
    },
    UPDATE_FLOW_BUILDER_ACCORDIONS(
      state: { flowBuilderAccordions: any },
      data: any
    ) {
      state.flowBuilderAccordions = {
        ...state.flowBuilderAccordions,
        ...data,
      };
    },
    SET_FLOW_INFO_FOR_SEND_EMAIL(
      state: { flowInfoForSendEmail: any },
      data: any
    ) {
      state.flowInfoForSendEmail = {
        ...state.flowInfoForSendEmail,
        ...data,
      };
    },
    SET_METRICS_TYPE_LIST(state: { metricsTypeList: any }, data: any) {
      state.metricsTypeList = data;
    },
    SET_METRICS_TYPE_LIST_LOADING(
      state: { isMetricsTypeListLoading: any },
      data: any
    ) {
      state.isMetricsTypeListLoading = data;
    },
    SET_CUSTOM_EVENT_TYPE_ID(state: { CustomEventTypeId: any }, data: any) {
      state.CustomEventTypeId = data;
    },
    SET_NOTIFICATION_OBJECT(state: { notificationObject: any }, data: any) {
      state.notificationObject = {
        notifyType: data.notifyType,
        notifyMessage: data.notifyMessage,
        isNotify: data.isNotify,
      };
      //clear object after 4 second
      setTimeout(() => {
        state.notificationObject = {
          notifyType: "",
          notifyMessage: "",
          isNotify: false,
        };
      }, 4000);
    },
    SET_BASIC_EMAIL_CAMPAIGN_LIST(
      state: { basicEmailCampaignList: any },
      data: any
    ) {
      state.basicEmailCampaignList = data;
    },
    SET_FLOW_ANALYTICS_MODAL_VISIBILITY(
      state: { flowAnalyticsModalVisibility: any },
      data: any
    ) {
      state.flowAnalyticsModalVisibility = data;
    },
    SET_SELECTED_FLOW_ELEMENT(state: { selectedFlowElement: any }, data: any) {
      state.selectedFlowElement = data;
    },
    SET_IS_EDIT_MODE_NODE_DROPDOWN(
      state: { isInEditMode: Boolean },
      data: Boolean
    ) {
      state.isInEditMode = data;
    },
    SET_DATE_ATTRIBUTE_FIELDS(state: { dateAttributeFields: any }, data: any) {
      state.dateAttributeFields = data;
    },
    SET_DATE_ATTRIBUTE_FIELDS_LOADING(
      state: { isDateAttributeFieldsLoading: any },
      data: any
    ) {
      state.isDateAttributeFieldsLoading = data;
    },
    SET_PROFILE_UPDATE_FIELDS(state: { profileUpdateFields: any }, data: any) {
      state.profileUpdateFields = data;
    },
    SET_PROFILE_UPDATE_FIELDS_LOADING(
      state: { isProfileUpdateFieldsLoading: any },
      data: any
    ) {
      state.isProfileUpdateFieldsLoading = data;
    },
  },
  actions: {
    getMetricsTypeList({ commit }: any) {
      commit("SET_METRICS_TYPE_LIST_LOADING", true);
      getMetricsTypeListService()
        .then((response: any) => {
          commit("SET_METRICS_TYPE_LIST_LOADING", false);
          commit("SET_METRICS_TYPE_LIST", response?.data.data);
        })
        .catch(() => {
          commit("SET_METRICS_TYPE_LIST_LOADING", false);
          commit("SET_METRICS_TYPE_LIST", []);
        });
    },
    getBasicEmailCampaignList({ commit }: any, payload: any) {
      getBasicCampaignListService(payload)
        .then((response: any) => {
          commit("SET_BASIC_EMAIL_CAMPAIGN_LIST", response?.data.data);
        })
        .catch(() => {
          commit("SET_BASIC_EMAIL_CAMPAIGN_LIST", []);
        });
    },
    getDateAttributeFields({ commit }: any, payload: any) {
      commit("SET_DATE_ATTRIBUTE_FIELDS_LOADING", true);
      getProfileFieldsService(payload)
        .then((response: any) => {
          commit("SET_DATE_ATTRIBUTE_FIELDS_LOADING", false);
          commit("SET_DATE_ATTRIBUTE_FIELDS", response?.data?.data);
        })
        .catch(() => {
          commit("SET_DATE_ATTRIBUTE_FIELDS_LOADING", false);
          commit("SET_DATE_ATTRIBUTE_FIELDS", []);
        });
    },
    getProfileUpdateFields({ commit }: any, payload: any) {
      commit("SET_PROFILE_UPDATE_FIELDS_LOADING", true);
      getProfileFieldsService(payload)
        .then((response: any) => {
          commit("SET_PROFILE_UPDATE_FIELDS_LOADING", false);
          commit("SET_PROFILE_UPDATE_FIELDS", response?.data?.data);
        })
        .catch(() => {
          commit("SET_PROFILE_UPDATE_FIELDS_LOADING", false);
          commit("SET_PROFILE_UPDATE_FIELDS", []);
        });
    },
  },
};
export default flowAutomationStore;
