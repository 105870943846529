import { userValidation } from "../utils/helper";

const digitalPassAudience = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-lists",
    name: "DpLists",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/lists/Lists.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "Lists",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/dp-configurefields",
    name: "DPConfigureFields",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "All Audience",
          to: {
            name: "DpAllAudience",
            props: true,
            params: true,
          },
        },
        {
          text: "Configure Fields",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/audience/allAudience/configureFields/ConfigureFields.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-lists/:listId",
    name: "DpSubscribersLists",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/lists/listWithTabs.vue"
        ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "Lists",
          to: {
            name: "DpLists",
          },
        },
        {
          text: "listName",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-segments",
    name: "DpSegments",
    props: true,
    params: true,
    component: () =>
      import("../components/clubDetails/audience/segments/Segments.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "Segments",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-segment/create",
    name: "DpSegmentCreate",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/segments/CreateEditSegment/CreateUpdateSegment.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "Segments",
          to: {
            name: "DpSegments",
            props: true,
          },
        },
        {
          text: "Create",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-segment/edit",
    name: "DpSegmentEdit",
    props: true,
    params: true,
    component: () =>
      import(
        "../components/clubDetails/audience/segments/CreateEditSegment/CreateUpdateSegment.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "Segments",
          to: {
            name: "DpSegments",
            props: true,
          },
        },
        {
          text: "Edit",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/digitalpass-tags",
    name: "DpTags",
    props: true,
    params: true,
    component: () => import("../components/clubDetails/audience/tags/Tags.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Digital Pass",
          to: {
            name: "DigitalPass",
            props: true,
          },
        },
        {
          text: "Tags",
        },
      ],
      requiresAuth: true,
    },
  },
];

export default digitalPassAudience;
