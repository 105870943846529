import {
  getAudienceVariationService,
  // getCampaignPerformanceOvertimeService,
  getAudienceOverallPerformanceService,
  getAudiencePerformanceByLocationService,
  getAudienceClientDeviceService,
  getAudiencePerformanceBenchmarkService,
} from "@/services/clubDetails/listSegmentReport/listSegmentReport.service";
import {
  percentageCalculator,
  percentageCalculatorWithFraction,
} from "@/utils/helper";
import { countries } from "@/utils/helperObjects";

import store from "@/store";

export type State = {
  audienceVariationsLoader: Boolean;
  audienceVariationsData: Object;

  audienceOverallPerformanceData: Object;
  audienceOverallPerformanceLoader: Boolean;

  // audienceCamapignLoader: Boolean,
  // audienceCamapignData: Object,

  audiencePerformanceOverTimeData: Object;
  audiencePerformanceOverTimeLoader: Boolean;

  audienceClientDevicesData: Object;
  audienceClientDevicesLoader: Boolean;

  audiencePerformanceByLocationData: Object;
  audiencePerformanceByLocationLoader: Boolean;

  audiencePerformanceBenchmarkData: Object;
  audiencePerformanceBenchmarkLoader: Boolean;
};

const state: State = {
  audienceVariationsLoader: false,
  audienceVariationsData: {},

  audienceOverallPerformanceData: {},
  audienceOverallPerformanceLoader: false,

  // audienceCamapignLoader: false,
  // audienceCamapignData: {},

  audiencePerformanceOverTimeData: {},
  audiencePerformanceOverTimeLoader: false,

  audienceClientDevicesData: {},
  audienceClientDevicesLoader: false,

  audiencePerformanceByLocationData: [],
  audiencePerformanceByLocationLoader: false,

  audiencePerformanceBenchmarkData: [],
  audiencePerformanceBenchmarkLoader: false,
};

const listSegmentReport = {
  state,
  getters: {},
  mutations: {
    SET_AUDIENCE_VARIATIONS_LOADER(
      state: { audienceVariationsLoader: boolean },
      data: boolean
    ) {
      state.audienceVariationsLoader = data;
    },
    SET_AUDIENCE_VARIATIONS_DATA(
      state: { audienceVariationsData: Object },
      data: Object
    ) {
      state.audienceVariationsData = data;
    },
    SET_AUDIENCE_OVERALL_PERFORMANCE_LOADER(
      state: { audienceOverallPerformanceLoader: boolean },
      data: boolean
    ) {
      state.audienceOverallPerformanceLoader = data;
    },
    SET_AUDIENCE_OVERALL_PERFORMANCE_DATA(
      state: { audienceOverallPerformanceData: Object },
      data: Object
    ) {
      state.audienceOverallPerformanceData = data;
    },
    // SET_AUDIENCE_CAMPAIGN_LOADER(
    //     state: { audienceCamapignLoader: boolean },
    //     data: boolean
    // ) {
    //   state.audienceCamapignLoader = data;
    // },
    // SET_AUDIENCE_CAMPAIGN_DATA(
    //     state: { audienceCamapignData: Object },
    //     data: Object
    // ) {
    //   state.audienceCamapignData = data;
    // },
    SET_AUDIENCE_PERFORMANCE_OVER_TIME_LOADER(
      state: { audiencePerformanceOverTimeLoader: boolean },
      data: boolean
    ) {
      state.audiencePerformanceOverTimeLoader = data;
    },
    SET_AUDIENCE_PERFORMANCE_OVER_TIME_DATA(
      state: { audiencePerformanceOverTimeData: Object },
      data: Object
    ) {
      state.audiencePerformanceOverTimeData = data;
    },
    SET_AUDIENCE_CLIENT_DEVICES_LOADER(
      state: { audienceClientDevicesLoader: boolean },
      data: boolean
    ) {
      state.audienceClientDevicesLoader = data;
    },
    SET_AUDIENCE_CLIENT_DEVICES_DATA(
      state: { audienceClientDevicesData: Object },
      data: Object
    ) {
      state.audienceClientDevicesData = data;
    },
    SET_AUDIENCE_PERFORMANCE_BY_LOCATION_LOADER(
      state: { audiencePerformanceByLocationLoader: boolean },
      data: boolean
    ) {
      state.audiencePerformanceByLocationLoader = data;
    },
    SET_AUDIENCE_PERFORMANCE_BY_LOCATION_DATA(
      state: { audiencePerformanceByLocationData: Object },
      data: Object
    ) {
      state.audiencePerformanceByLocationData = data;
    },
    SET_AUDIENCE_PERFORMANCE_BENCHMARK_LOADER(
      state: { audiencePerformanceBenchmarkLoader: boolean },
      data: boolean
    ) {
      state.audiencePerformanceBenchmarkLoader = data;
    },
    SET_AUDIENCE_PERFORMANCE_BENCHMARK_DATA(
      state: { audiencePerformanceBenchmarkData: Object },
      data: Object
    ) {
      state.audiencePerformanceBenchmarkData = data;
    },
    clearListSegmentReportData(state: any) {
      state.audienceVariationsLoader = false;
      state.audienceVariationsData = {};

      state.audienceOverallPerformanceData = {};
      state.audienceOverallPerformanceLoader = false;

      state.audiencePerformanceOverTimeData = {};
      state.audiencePerformanceOverTimeLoader = false;

      state.audienceClientDevicesData = {};
      state.audienceClientDevicesLoader = false;

      state.audiencePerformanceByLocationData = [];
      state.audiencePerformanceByLocationLoader = false;

      state.audiencePerformanceBenchmarkData = [];
      state.audiencePerformanceBenchmarkLoader = false;
    }
  },
  actions: {
    async getAudienceVariations(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_AUDIENCE_VARIATIONS_LOADER", true);
      try {
        const response = await getAudienceVariationService(payload);
        commit("SET_AUDIENCE_VARIATIONS_DATA", response?.data?.data);
        commit("SET_AUDIENCE_VARIATIONS_LOADER", false);
      } catch (error: any) {
        commit("SET_AUDIENCE_VARIATIONS_LOADER", false);
        const message = error.response.data?.Status?.Message;
        store.commit("SHOW_NOTIFICATION", {
          message: message || "Something went wrong. Please try again later.",
          type: "error",
        });
      }
    },
    async getAudienceOverallPerformance(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_AUDIENCE_OVERALL_PERFORMANCE_LOADER", true);
      try {
        const response = await getAudienceOverallPerformanceService(payload);
        console.log(response, "getOveralldata");
        commit("SET_AUDIENCE_OVERALL_PERFORMANCE_DATA", response?.data?.data);
        // ;
        commit("SET_AUDIENCE_OVERALL_PERFORMANCE_LOADER", false);
      } catch (error) {
        commit("SET_AUDIENCE_OVERALL_PERFORMANCE_LOADER", false);
        throw error;
      }
    },
    // async getAudienceCampaign(
    //     { commit }: { commit: Function },
    //     payload: any
    // ) {
    //   commit("SET_AUDIENCE_CAMPAIGN_LOADER", true);
    //   try {
    //     const response = await getAudienceCampaignService(payload);
    //     commit("SET_AUDIENCE_CAMPAIGN_DATA", response?.data?.data);
    //     // ;
    //     commit("SET_AUDIENCE_CAMPAIGN_LOADER", false);
    //   } catch (error) {
    //     commit("SET_AUDIENCE_CAMPAIGN_LOADER", false);
    //     throw error;
    //   }
    // },
    // async getCampaignPerformanceOverTime(
    //   { commit }: { commit: Function },
    //   payload: any
    // ) {
    //   commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER", true);
    //   try {
    //     const response = await getCampaignPerformanceOvertimeService(payload);
    //     commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_DATA", response?.data?.data);
    //     // ;
    //     commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER", false);
    //   } catch (error) {
    //     commit("SET_CAMPAIGN_PERFORMANCE_OVER_TIME_LOADER", false);
    //     throw error;
    //   }
    // },
    async getAudienceClientDevices(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_AUDIENCE_CLIENT_DEVICES_LOADER", true);
      try {
        const response = await getAudienceClientDeviceService(payload);
        const data = response?.data?.data;
        //for of loop
        let overAllData = [];
        let desktopData = [];
        let mobileData = [];
        let totalDesktop = 0;
        let totalMobile = 0;
        for (const element of data) {
          let obj = {
            ...element,
            value: (
              (element?.User_Per_Group / element?.Total_User) *
              100
            ).toFixed(2),
            category: element?.UserAgent,
          };
          overAllData.push(obj);
          if (obj?.DeviceType === "Desktop") {
            if (obj?.value) {
              totalDesktop += Number(obj?.value);
              desktopData.push(obj);
            }
          }
          if (obj?.DeviceType === "Mobile") {
            if (obj?.value) {
              totalMobile += Number(obj?.value);
              mobileData.push(obj);
            }
          }
        }

        const overAllDataObj = {
          overall: overAllData,
          desktop: desktopData,
          mobile: mobileData,
          selected: overAllData,
          totalDesktop : totalDesktop?.toFixed(2),
          totalMobile : totalMobile?.toFixed(2),
        };

        commit("SET_AUDIENCE_CLIENT_DEVICES_DATA", overAllDataObj);
        commit("SET_AUDIENCE_CLIENT_DEVICES_LOADER", false);
      } catch (error) {
        commit("SET_AUDIENCE_CLIENT_DEVICES_LOADER", false);
        throw error;
      }
    },
    async getAudiencePerformanceByLocation(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_AUDIENCE_PERFORMANCE_BY_LOCATION_LOADER", true);
      try {
        const response = await getAudiencePerformanceByLocationService(payload);
        const data = response?.data?.data;
        let overAllData = [];
        for (const element of data) {
          let obj = {
            ...element,
            value: (
              (element?.User_Per_Group / element?.Total_User) *
              100
            )?.toFixed(2),
            // @ts-ignore
            category: countries[element?.Country],
          };
          overAllData.push(obj);
        }

        commit("SET_AUDIENCE_PERFORMANCE_BY_LOCATION_DATA", overAllData);
        commit("SET_AUDIENCE_PERFORMANCE_BY_LOCATION_LOADER", false);
      } catch (error) {
        commit("SET_AUDIENCE_PERFORMANCE_BY_LOCATION_LOADER", false);
        throw error;
      }
    },
    async getAudiencePerformanceBenchmark(
      { commit }: { commit: Function },
      payload: any
    ) {
      commit("SET_AUDIENCE_PERFORMANCE_BENCHMARK_LOADER", true);
      try {
        const response = await getAudiencePerformanceBenchmarkService(payload);
        const data = response?.data?.data;
        let overAllData = [];
        for (const element of data) {
          let obj = {
            ...element,
            clickRate: percentageCalculatorWithFraction(
              element?.UniqueClicked,
              element?.UniqueDelivered
            ),
            dataValue: [
              percentageCalculatorWithFraction(
                element?.UniqueClicked,
                element?.UniqueDelivered
              ),
            ],
            openRate: percentageCalculatorWithFraction(
              element?.UniqueOpened,
              element?.UniqueDelivered
            ),
            converted: percentageCalculatorWithFraction(
              element?.TotalConversion,
              element?.UniqueDelivered
            ),
            bouncedRate: percentageCalculatorWithFraction(
              element?.UniqueBounced,
              element?.UniqueSent
            ),
            name: element?.CampaignName,
          };
          overAllData.push(obj);
        }

        commit("SET_AUDIENCE_PERFORMANCE_BENCHMARK_DATA", overAllData);
        // ;
        commit("SET_AUDIENCE_PERFORMANCE_BENCHMARK_LOADER", false);
      } catch (error) {
        commit("SET_AUDIENCE_PERFORMANCE_BENCHMARK_LOADER", false);
        throw error;
      }
    },
  },
};
export default listSegmentReport;
