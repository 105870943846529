import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from "axios";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Toaster from "@/components/common/Toaster/Toaster.vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import * as helpers from "../src/utils/globalHelpers";

library.add(faUserSecret);
const app = createApp(App);
// app.config.unwrapInjectedRef = true;

Object.entries(helpers).forEach(([key, value]) => {
  app.config.globalProperties["$" + key] = value;
});

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return router.push("/");
    }
  }
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(ElementPlus)
  .use(router)
  .use(store)
  .component("Toaster", Toaster)
  .mount("#app");
