import { DELETE, GET, POST, PUT } from "@/service";

export const getTags = (model: any) => {
  return GET(`api/v1/Audience/tags`, { params: model });
};

export const getTag = (model: any) => {
  return GET(`api/v1/Audience/tags/${model?.id}`, { params: model });
};

export const addTag = (model: any) => {
  return POST(`api/v1/Audience/tags`, model);
};

export const renameTag = (model: any) => {
  return PUT(`api/v1/Audience/tags/${model.id}/rename`, model);
};

export const removeTag = (model: any) => {
  return DELETE(`api/v1/Audience/Tags`, { data: model });
};
