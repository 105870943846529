<kendo-notification
  append-to="#appendto"
  ref="notification"
  :position-top="30"
  :position-right="30"
  :position-left="30"
  :auto-hide-after="15000"
  stacking="down"
>
</kendo-notification>
<div class="notification-center" id="appendto"></div>
