import { auth } from "./../../services/auth.service";
import { configuration } from "@/configurationProvider";
import MapDataToPassModel from "@/models/digitalPass/MapDataToPassModel";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { DELETE, GET, POST } from "@/service";
import axios from "axios";
import {
  getRecipientsService,
  getPassColumnsService,
  getAudienceCustomFieldsService,
  getPassTypeAttributesService,
  getAttributeMapService,
} from "../../services/clubDetails/manageRecipients.service";

export type State = {
  columnList: any[];
  customFields: any[];
  customFieldsModifier: [];
  memberModalVisible: boolean;
  columnModifier: any[];
  members: any;
  items: any;
  attributeList: any[];
  setRecipients: any;
  searchQuery: any;
  passStatus: any;
  emailStatus: any;
  status: SnackBarModel;
  loading: boolean;
  showMsg: boolean;
  visible: boolean;
  attributeMap: any;
  successStatus: object;
  errorStatus: object;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
};
const state: State = {
  columnList: [],
  columnModifier: [],
  customFields: [],
  customFieldsModifier: [],
  memberModalVisible: false,
  items: [],
  members: {},
  attributeList: [],
  setRecipients: {},
  searchQuery: "",
  passStatus: "",
  emailStatus: "",
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  showMsg: false,
  loading: false,
  visible: false,
  attributeMap: [],
  successStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  errorStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  showErrorMsg: false,
  showSuccessMsg: false,
};
const manageRecipients = {
  state,
  getters: {},
  mutations: {
    SET_COLUMNS(state: { columnList: any }, data: any) {
      state.columnList = data;
    },
    SET_COLUMNSMODIFIER(state: { columnModifier: any }, data: any) {
      state.columnModifier = data;
    },
    SET_COLUMNS_CUSTOM_FIELDS(state: { customFields: any }, data: any) {
      state.customFields = data;
    },
    SET_COLUMNSMODIFIER_CUSTOM_FIELDS(
      state: { customFieldsModifier: any },
      data: any
    ) {
      state.customFieldsModifier = data;
    },
    SET_ATTRIBUTE(state: { attributeList: any }, data: any) {
      state.attributeList = data;
    },
    SET_RECIPIENTS(state: { setRecipients: any }, data: any) {
      state.setRecipients = data;
    },
    LOADING_STATUS(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    SET_SEARCH_QUERY(state: { searchQuery: any }, data: any) {
      state.searchQuery = data;
    },
    SET_PASS_STATUS(state: { passStatus: any }, data: any) {
      state.passStatus = data;
    },
    SET_EMAIL_STATUS(state: { emailStatus: any }, data: any) {
      state.emailStatus = data;
    },
    SET_SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    SET_CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
    SET_MODAL_VISIBLE(state: { visible: object }, data: any) {
      state.visible = data;
    },
    SET_ATTRIBUTE_MAP(state: { attributeMap: any }, data: any) {
      state.attributeMap = data;
    },
    SET_SUCCESS_STATUS(state: { successStatus: object }, data: any) {
      state.successStatus = data;
    },
    SET_ERROR_STATUS(state: { errorStatus: object }, data: any) {
      state.errorStatus = data;
    },
    SET_SUCCESS_MESSAGE_ISSHOW(state: { showSuccessMsg: boolean }, data: any) {
      state.showSuccessMsg = data;
    },
    SET_ERROR_MESSAGE_ISSHOW(state: { showErrorMsg: boolean }, data: any) {
      state.showErrorMsg = data;
    },
  },
  actions: {
    getRecipients({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      getRecipientsService(payload)
        .then((response) => {
          commit("SET_RECIPIENTS", response.data.data);
          // setTimeout(() => {
          commit("LOADING_STATUS", false);

          commit("SET_SEARCH_QUERY", payload.searchQuery);
          commit("SET_PASS_STATUS", payload.passStatus);
          commit("SET_EMAIL_STATUS", payload.emailStatus);
          // }, 7000);
        })
        .catch(() => {
          commit("SET_RECIPIENTS", null);
          commit("LOADING_STATUS", false);
        });
    },
    getPassColumns({ commit }: any, payload: any) {
      getPassColumnsService(payload)
        .then((response) => {
          commit("SET_COLUMNS", response.data.columns);
          commit("SET_COLUMNSMODIFIER", response.data.columns);
        })
        .catch((error) => {});
    },
    getAudienceCustomFields({ commit }: any, payload: any) {
      getAudienceCustomFieldsService(payload)
        .then((response) => {
          commit("SET_COLUMNS_CUSTOM_FIELDS", response.data);
          commit("SET_COLUMNSMODIFIER_CUSTOM_FIELDS", response.data);
        })
        .catch((error) => {});
    },
    getPassTypeAttributes({ commit }: any, payload: any) {
      getPassTypeAttributesService(payload)
        .then((response) => {
          commit("SET_ATTRIBUTE", response.data.data);
        })
        .catch((error) => {});
    },
    // importDigitalPassMappedData({ dispatch, commit }: any, payload: MapDataToPassModel) {
    //     commit("LOADING_STATUS", true);

    //     var payloadGetResipients = {
    //         clubId: payload.clubId,
    //         templateId: payload.templateId
    //     }
    //     POST(`api/v1/Pass/import`, payload)
    //         .then((response) => {
    //             commit("LOADING_STATUS", false);
    //             commit("SET_SHOW_MESSAGE", true);
    //             var model = new SnackBarModel;
    //             model.type = "success";
    //             model.Class = "status-error"
    //             model.Message = "Data for digital pass has been imported successfully."
    //             commit("SET_CONTROL_STATUS", model);
    //             commit("SET_SHOW_MESSAGE", true);
    //             setTimeout(() => {
    //                 commit("SET_SHOW_MESSAGE", false);
    //             }, 2000);
    //             dispatch("getRecipients", payloadGetResipients);
    //         })
    //         .catch(error => {
    //             commit("LOADING_STATUS", false);
    //
    //         })
    // },

    async recipientsExport({ commit, dispatch }: any, payload: any) {
      commit("LOADING_STATUS", true);

      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Pass/export/`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      }).then((response) => {
        commit("LOADING_STATUS", false);
        commit("SET_SHOW_MESSAGE", true);
        var model = new SnackBarModel();
        model.type = "success";
        model.Class = "status-error";
        model.Message = "Report downloaded successfully.";
        commit("SET_CONTROL_STATUS", model);
        commit("SET_SHOW_MESSAGE", true);
        setTimeout(() => {
          commit("SET_SHOW_MESSAGE", false);
        }, 2000);
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        let filename = "Pass users data.xlsx";
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    deleteRecipients({ dispatch, commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      var payloadDelete = {
        clubId: payload.clubId,
        ids: payload.ids,
      };
      var payloadGetResipients = {
        clubId: payload.clubId,
        templateId: payload.templateId,
      };
      DELETE(`api/v1/Pass/delete/bulk/`, {
        data: payloadDelete,
      })
        .then((response) => {
          commit("LOADING_STATUS", false);

          if (response.data?.data?.errorCount) {
            var model = new SnackBarModel();
            model.type = "error";
            model.Class = "status-error";
            model.Message = `Couldn't delete ${response.data?.data?.errorCount} members as they are in use by one or more templates.`;

            commit("SET_ERROR_STATUS", model);

            commit("SET_ERROR_MESSAGE_ISSHOW", true);
            setTimeout(() => {
              commit("SET_ERROR_MESSAGE_ISSHOW", false);
            }, 10000);
          }

          if (response.data?.data?.successCount) {
            var model = new SnackBarModel();
            model.type = "success";
            model.Class = "status-error";
            model.Message = `Successfully deleted ${response.data?.data?.successCount} recipients from the manage recipients list.`;

            commit("SET_SUCCESS_STATUS", model);

            commit("SET_SUCCESS_MESSAGE_ISSHOW", true);
            setTimeout(() => {
              commit("SET_SUCCESS_MESSAGE_ISSHOW", false);
            }, 10000);
          }

          dispatch("getRecipients", payloadGetResipients);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
          // commit("SET_SHOW_MESSAGE", true);
          // var model = new SnackBarModel;
          // model.type = "error";
          // model.Class = "status-error"
          // model.Message = "You cannot delete recipients whose email status is sent."
          // commit("SET_CONTROL_STATUS", model);
          // commit("SET_SHOW_MESSAGE", true);
          // setTimeout(() => {
          //     commit("SET_SHOW_MESSAGE", false);
          // }, 2000);
          dispatch("getRecipients", payloadGetResipients);
        });
    },
    // sentDigitalPass({ dispatch, commit }: any, payload: any) {

    //     var payloadOriginal = {
    //         clubId: payload.clubId,
    //         ids: payload.ids
    //     }

    //     var payloadGetResipients = {
    //         clubId: payload.clubId,
    //         templateId: payload.templateId
    //     }

    //     commit("LOADING_STATUS", true);
    //     POST(`api/v1/pass/send-email/bulk`, payloadOriginal)
    //         .then((response) => {
    //             dispatch("getRecipients", payloadGetResipients);

    //             commit("LOADING_STATUS", false);
    //             commit("SET_SHOW_MESSAGE", true);
    //             var model = new SnackBarModel;
    //             model.type = "error";
    //             model.Class = "status-error"
    //             model.Message = "Digital Pass emails have been sent to selected members"
    //             commit("SET_CONTROL_STATUS", model);
    //             commit("SET_SHOW_MESSAGE", true);
    //             setTimeout(() => {
    //                 commit("SET_SHOW_MESSAGE", false);
    //             }, 3000);
    //         })
    //         .catch(error => {
    //
    //         })
    // },
    // addMembersToTemplate({ dispatch, commit }: any, payload: any) {
    //     commit("LOADING_STATUS", true);
    //     var payloadGetResipients = {
    //         clubId: payload.clubId,
    //         templateId: payload.templateId
    //     }
    //     POST(`api/v1/pass/add-template-members`, payload)
    //         .then((response) => {
    //             commit("LOADING_STATUS", false);
    //             commit("SET_SHOW_MESSAGE", true);
    //             var model = new SnackBarModel;
    //             model.type = "error";
    //             model.Class = "status-error"
    //             model.Message = "New recipients added to the list from club."
    //             commit("SET_CONTROL_STATUS", model);
    //             commit("SET_SHOW_MESSAGE", true);
    //             setTimeout(() => {
    //                 commit("SET_SHOW_MESSAGE", false);
    //             }, 2000);
    //             dispatch("getRecipients", payloadGetResipients);
    //         })
    //         .catch(error => {
    //
    //         })
    // },
    getAttributeMap({ commit }: any, payload: any) {
      getAttributeMapService(payload)
        .then((response) => {
          commit("SET_ATTRIBUTE_MAP", response.data.data);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
  },
};
export default manageRecipients;
