import AddClubAndAssociationModel from "@/models/club/AddClubAndAssociationModel";
import { DELETE, GET, POST, PUT } from "@/service";

export const addClubService = (model: AddClubAndAssociationModel) =>  {
    return POST(`api/v1/Club`, model);
};

export const getClubsService = (payload: any) =>  {
    return GET(`api/v1/Club`, { params: payload })
};
export const getClubService = (clubId: number) =>  {
    return GET(`api/v1/Club/${clubId}`)
};

export const updateClubService = (payload: any) =>  {
    return PUT(`api/v1/Club/${payload.id}`, payload);
};

export const deleteClubService = (clubId: number) =>  {
    return DELETE(`api/v1/Club/${clubId}`, { data: { clubId } })
};