import store from "@/store";
import moment from "moment";

export function getPermissions() {
  return store?._state?.data?.common?.menuWithPermission;
}

export function hasAccessToModule(authdata) {
  const { permissionKey, moduleCode, itemCode, childItemCode } = authdata;
  let menues = getPermissions();

  if (menues?.length) {
    let permission = menues?.find((menu) => menu.code === moduleCode);
    if (!permission) return false;
    if (itemCode) {
      let item = permission.items?.find((item) => item.code === itemCode);
      if (!item) return false;
      if (childItemCode) {
        let childItem = item.items?.find(
          (childItem) => childItem.code === childItemCode
        );
        if (!childItem) return false;
        return childItem[permissionKey];
      }
      return item[permissionKey];
    } else {
      return permission[permissionKey];
    }
  } else {
    return false;
  }
}

export function flowAnalyticsModalHandler(value, nodeDta) {
  store.commit("SET_SELECTED_FLOW_ELEMENT", nodeDta);
  store.commit("SET_FLOW_ANALYTICS_MODAL_VISIBILITY", value);
}

export function globalDateFormatter(date) {
  // check if date is an instance of Date
  if (date && date != null) {
    return moment(date).format("DD-MMM-YYYY");
  } else {
    return "-";
  }
}

export function addPxIfMissing(value) {
  return value
    ? String(value).endsWith("px")
      ? value
      : `${value}px`
    : undefined;
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    store.commit("SHOW_NOTIFICATION", {
      message: "Copied to clipboard",
      type: "success",
    })
  );
}
