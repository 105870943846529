export default class GetAudienceModel {
    public clubId: number = 0
    public entityType: number = 0
    public searchQuery: string = ""
    public exceptListId: any = null
    public exceptTemplateId: any = null
    public exceptVoucherCampaignId : any =  null
    public listId: number = 0
    public tagId: number = 0
    public segmentId: number = 0
    public limit: number = 100
    public offset: number = 0


}

