<div>
  <div ref="infoBox" class="dashboard-wrap min-h-100vh"
    :class="
        resPonsiveSidebarOpen === 'close'
          ? 'responsive-sidebar-close'
          : resPonsiveSidebarOpen === 'open' ? 'responsive-sidebar-open' : '', $route.name == 'EmailTemplateEditorCreate' ||  $route.name == 'EmailTemplateEditorUpdate' || $route.name == 'workflowEditorUpdate' || $route.name == 'workflowEditor' ? 'desktop-view-mobile' : $route.name == 'flowCreateCampaign' ? 'configure-email-content' : '' ">
    <LeftSideBar v-if="
          !isPageNotFoundVisible &&
          $route.name != 'Login' &&
          $route.name != 'SystemDashboard' &&
          $route.name != 'ClubDetails' &&
          $route.name != 'invitation' &&
          $route.name != 'DigitalpassGenerate' &&
          $route.name != 'SubscribeUnsubscribeECModal' &&
          $route.name != 'UpdatePreferences' &&
          $route.name != 'viewInBrowser' &&
          $route.name != 'authcallback' &&
          $route.name != 'authcallbacksignout' &&
          $route.name != 'pageNotFound' &&
          $route.name != 'EmailTemplateEditorCreate' &&
          $route.name != 'EmailTemplateEditorUpdate' &&
          $route.name != 'flowCreateCampaign' &&
          $route.name != 'flowEditCampaign' &&
          $route.name != 'workflowEditorUpdate' &&
          $route.name != 'workflowEditor'" :id="$route.params.id" :club-id="$route.params.id"
      :sidebarToggleHandler="sidebarToggleHandler" :resPonsiveSidebarOpen="resPonsiveSidebarOpen" />

    <main class="dashboard-right flex-grow-1">
      <AppLoader v-if="isMenuLoading" />
      <template v-else>
        <router-view v-if="$route.name != 'LeftSideBar'" />
      </template>
    </main>
  </div>
</div>