import { getEmailCampaignAnalytics } from "@/services/clubDetails/emailCampaign.service";

export type State = {
  emailCampaignAnalyticsLoader: Boolean;
  emailCampaignAnalyticsData: Object;
  isCampaignExist: Boolean;
};

const state: State = {
  emailCampaignAnalyticsLoader: false,
  emailCampaignAnalyticsData: [],
  isCampaignExist: true,
};

const emailCampaignAnalytics = {
  state,
  getters: {},
  mutations: {
    SET_EMAIL_CAMPAIGN_ANALYTICS_LOADER(
      state: { emailCampaignAnalyticsLoader: boolean },
      data: boolean
    ) {
      state.emailCampaignAnalyticsLoader = data;
    },
    SET_EMAIL_CAMPAIGN_ANALYTICS_DATA(
      state: { emailCampaignAnalyticsData: Object },
      data: boolean
    ) {
      state.emailCampaignAnalyticsData = data;
    },
    SET_IS_CAMPAIGN_EXIST(state: { isCampaignExist: Boolean }, data: boolean) {
      state.isCampaignExist = data;
    },
  },
  actions: {
    getEmailCampaignAnalytics({ commit }: any, payload: any) {
      commit("SET_EMAIL_CAMPAIGN_ANALYTICS_LOADER", true);
      getEmailCampaignAnalytics(payload)
        .then((response) => {
          commit("SET_EMAIL_CAMPAIGN_NAME", response?.data.data.campaignName);
          commit("SET_EMAIL_CAMPAIGN_ANALYTICS_DATA", response?.data.data);
          commit("SET_EMAIL_CAMPAIGN_ANALYTICS_LOADER", false);

          if (response?.data.data?.campaignId == 0) {
            commit("SET_IS_CAMPAIGN_EXIST", false);
          }
        })
        .catch(() => {
          commit("SET_EMAIL_CAMPAIGN_ANALYTICS_LOADER", false);
          commit("SET_EMAIL_CAMPAIGN_NAME", "Error!");
          commit("SET_EMAIL_CAMPAIGN_ANALYTICS_DATA", []);
        });
    },
  },
};
export default emailCampaignAnalytics;
