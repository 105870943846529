import { userValidation } from "../utils/helper";

const settingsRoute = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/settings",
    name: "Settings",
    props: true,
    meta: {
      // breadcrumb: [
      //     { name: 'Home', link: '/' },
      //     { name: 'clubname' },
      //     { name: 'Settings' },
      // ],
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Settings" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/settings/SettingComponent.vue"),
  },
];

export default settingsRoute;
