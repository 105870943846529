import moment from "moment";

const month_names_short = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const userValidation = () => {
  const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
  if (clubInfo?.roleId === 2) {
    window.location.href = "/";
  } else {
    return true;
  }
};

const insertElement = (array, index, element) => {
  array.splice(index, 0, element);
  return array;
};

const errorMessageHandler = (error) => {
  if (typeof error === "object" && error !== null && !Array.isArray(error)) {
    const errorsArrays = Object.values(error);
    const allErrors = errorsArrays.flat();
    return allErrors?.[0] || "Something went wrong.";
  }
};

const isPastDate = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  return date < today;
};

const GetFormattedDate = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return day + "-" + month_names_short?.[month - 1] + "-" + year;
};

function changeTimeZone(date, timeZone) {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    ).toUTCString();
  }
  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  ).toUTCString();
}

function getDate(date) {
  if (date != null) {
    return moment(date).format("DD-MMM-YYYY");
  }
}

function randomStringGenerator() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

function throttleFunction(cb, delay = 1000) {
  let shouldWait = false;
  let waitingArgs;
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      cb(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    cb(...args);
    shouldWait = true;
    setTimeout(timeoutFunc, delay);
  };
}

function debounceFunction(cb, delay = 250) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

function percentageCalculator(value, total) {
  if (!value || !total) {
    return 0;
  } else {
    if (isFinite(value / total)) {
      return Math.floor((value / total) * 100);
    } else {
      return 0;
    }
  }
}

function percentageCalculatorWithFraction(value, total) {
  if (!value || !total) {
    return 0;
  } else {
    if (isFinite(value / total)) {
      return ((value / total) * 100)?.toFixed(2);
    } else {
      return 0;
    }
  }
}

function generatePassword() {
  const specialChars = `!@#$%^&*()_+-=[]{}|;':,."<>?/~`;
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = lowercaseChars.toUpperCase();
  const numbers = "0123456789";

  let password = "";

  // Ensure at least one of each character type
  password += getRandomChar(lowercaseChars);
  password += getRandomChar(uppercaseChars);
  password += getRandomChar(numbers);
  password += getRandomChar(specialChars);

  const remainingLength = 10 - password.length;

  // Generate remaining characters randomly
  for (let i = 0; i < remainingLength; i++) {
    const charTypes = [lowercaseChars, uppercaseChars, numbers, specialChars];
    const randomTypeIndex = Math.floor(Math.random() * charTypes.length);
    const randomChar = getRandomChar(charTypes[randomTypeIndex]);
    password += randomChar;
  }

  // Shuffle the password characters to make it more random
  password = shuffleString(password);

  return password;
}

function getRandomChar(characters) {
  const randomIndex = Math.floor(Math.random() * characters.length);
  return characters[randomIndex];
}

function shuffleString(str) {
  const arr = str.split("");
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr.join("");
}

function generatePasswordErrorMessage(initial, messeges) {
  let message = initial;
  if (messeges.length == 1) {
    message += " " + messeges[0] + ".";
  } else if (messeges.length == 2) {
    message += " " + messeges[0] + " and " + messeges[1] + ".";
  } else {
    for (let i = 0; i < messeges.length; i++) {
      if (i == messeges.length - 1) {
        message += " and " + messeges[i] + ".";
      } else if (i == messeges.length - 2) {
        message += " " + messeges[i];
      } else {
        message += " " + messeges[i] + ",";
      }
    }
  }
  return message;
}

function onPasteHtmlIntoEditor(e) {
  const container = document.createElement("div");
  container.innerHTML = e.pastedHtml;
  const plainText = container.textContent || container.innerText || "";
  return plainText;
}

function formatDateTime(datetimeStr) {
  const date = moment(datetimeStr);
  const formattedDateTime = date.format('MMMM D, YYYY h:mm A');
  return formattedDateTime;
}

export {
  userValidation,
  errorMessageHandler,
  isPastDate,
  GetFormattedDate,
  insertElement,
  changeTimeZone,
  getDate,
  randomStringGenerator,
  throttleFunction,
  debounceFunction,
  percentageCalculator,
  percentageCalculatorWithFraction,
  generatePassword,
  generatePasswordErrorMessage,
  onPasteHtmlIntoEditor,
  formatDateTime
};
