import SnackBarModel from "@/models/snackBar/snackBarModel";
import { DELETE, GET } from "@/service";

export type State = {
  passTemplates: any;
  statusPassTemplate: SnackBarModel;
  loadingPassTemplate: boolean;
  showMsgPassTemplate: boolean;
  fetchController: "notfetched"; //Possible variable fetched , available , notavailable
};

const state: State = {
  passTemplates: {},
  showMsgPassTemplate: false,
  loadingPassTemplate: false,
  statusPassTemplate: {
    type: "",
    Class: "",
    Message: "",
  },
  fetchController: "notfetched", //Possible variable fetched , available , notavailable
};

const digitalPass = {
  state,
  getters: {},
  mutations: {
    SET_PASS_TEMPLATES(state: { passTemplates: any }, data: any) {
      state.passTemplates = data;
    },
    LOADING_STATUS(state: { loadingPassTemplate: boolean }, data: any) {
      state.loadingPassTemplate = data;
    },
    SHOW_MESSAGE(state: { showMsgPassTemplate: boolean }, data: any) {
      state.showMsgPassTemplate = data;
    },
    CONTROL_STATUS(state: { statusPassTemplate: object }, data: any) {
      state.statusPassTemplate = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },
  },
  actions: {
    getPassTemplates({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      return GET(`api/v1/Pass/clubs/${payload.id}/templates`, {
        params: payload,
      })
        .then((response) => {
          commit("LOADING_STATUS", false);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_PASS_TEMPLATES", response.data.data);
          if (response.data.data.items.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {});
    },
    deletePassTemplates({ dispatch, commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      return DELETE(
        `api/v1/Pass/clubs/${payload.id}/templates/${payload.templateId}`
      )
        .then((response) => {
          commit("LOADING_STATUS", false);
          var model = new SnackBarModel();
          model.type = "Success";
          model.Class = "status-success";
          model.Message = "Digital pass has been deleted.";
          commit("CONTROL_STATUS", model);
          commit("SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SHOW_MESSAGE", false);
          }, 2000);
          dispatch("getPassTemplates", payload);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
        });
    },
  },
};
export default digitalPass;
