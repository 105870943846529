import { DELETE, GET, POST, PUT } from "@/service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { errorMessageHandler } from "../../utils/helper";

export type State = {
  clubs: any;
  userDataLoading: boolean;
  usersData: any;
  addUserModalVisibility: boolean;
  addUserLoading: boolean;
  updateRoleLoading: boolean;
  deleteRoleMemberLoading: boolean;
  deleteRoleMemberPopUpVisibility: boolean;
  changeRolePopUpVisibility: boolean;
  showMsg: boolean;
  status: SnackBarModel;
  addUserErrors: any;
};

const state: State = {
  clubs: [],
  userDataLoading: false,
  usersData: {},
  addUserModalVisibility: false,
  addUserLoading: false,
  updateRoleLoading: false,
  deleteRoleMemberLoading: false,
  deleteRoleMemberPopUpVisibility: false,
  changeRolePopUpVisibility: false,
  showMsg: false,
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  addUserErrors: [],
};

const systemDashboard = {
  state,
  getters: {},
  mutations: {
    GET_CLUB_LIST(state: { clubs: any }, data: any) {
      state.clubs = data;
    },
    GET_USERS_DATA(state: { usersData: any }, data: any) {
      state.usersData = data;
    },
    USER_LIST_LOADING(state: { userDataLoading: boolean }, data: boolean) {
      state.userDataLoading = data;
    },
    ADD_USER_MODAL_VISIBILITY(
      state: { addUserModalVisibility: boolean },
      data: boolean
    ) {
      state.addUserModalVisibility = data;
    },
    ADD_USER_LOADING(state: { addUserLoading: boolean }, data: boolean) {
      state.addUserLoading = data;
    },
    UPDATE_ROLE_LOADING(state: { updateRoleLoading: boolean }, data: boolean) {
      state.updateRoleLoading = data;
    },
    DELETE_ROLE_MEMBER_LOADING(
      state: { deleteRoleMemberLoading: boolean },
      data: boolean
    ) {
      state.deleteRoleMemberLoading = data;
    },
    DELETE_ROLE_MEMBER_POP_UP_VISIBILITY(
      state: { deleteRoleMemberPopUpVisibility: boolean },
      data: boolean
    ) {
      state.deleteRoleMemberPopUpVisibility = data;
    },
    CHANGE_ROLE_POP_UP_VISIBILITY(
      state: { changeRolePopUpVisibility: boolean },
      data: boolean
    ) {
      state.changeRolePopUpVisibility = data;
    },
    SET_SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    SET_CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
    SET_ADD_USER_ERRORS(state: { addUserErrors: any }, data: any) {
      state.addUserErrors = data;
    },
  },
  actions: {
    notificationgenerator({ commit }: any, data: any) {
      const model = new SnackBarModel();
      model.type = data.type;
      model.Class = "status-error";
      model.Message = data.message;
      commit("SET_CONTROL_STATUS", model);
      commit("SET_SHOW_MESSAGE", true);
      setTimeout(() => {
        commit("SET_SHOW_MESSAGE", false);
      }, 4000);
    },
    // getClubs({ commit }: any) {
    //   return GET(`api/v1/Club`)
    //     .then((response) => {
    //       commit("GET_CLUB_LIST", response.data.data.items);
    //     })
    //     .catch(() => {
    //       commit("GET_CLUB_LIST", null);
    //     });
    // },
    getUsersList({ commit }: any, data: any) {
      commit("USER_LIST_LOADING", true);
      return GET(`api/v1/Club/${data.clubId}/role-members`, {
        params: data,
      })
        .then((response) => {
          commit("GET_USERS_DATA", response.data.data);
          commit("USER_LIST_LOADING", false);
        })
        .catch(() => {
          commit("GET_USERS_DATA", []);
          commit("USER_LIST_LOADING", false);
        });
    },
    addUser({ commit, dispatch }: any, data: any) {
      commit("ADD_USER_LOADING", true);
      return POST(`api/v1/Club/${data.clubId}/role-members`, data)
        .then((response) => {
          commit("ADD_USER_MODAL_VISIBILITY", false);
          commit("ADD_USER_LOADING", false);
          dispatch("notificationgenerator", {
            type: "success",
            message: "User added successfully",
          });
          dispatch("getUsersList", {
            clubId: data.clubId,
          });
          return;
        })
        .catch((error) => {
          commit("ADD_USER_LOADING", false);
          commit("SET_ADD_USER_ERRORS", error.response.data.errors);
        });
    },
    updateUserRole({ commit, dispatch }: any, data: any) {
      const payloadRole = {
        roleId: data.roleId,
        clubId: data.clubId,
        clubSystemUserId: data.clubSystemUserId,
      };

      const payloadSuccess = {
        clubId: data.clubId,
        searchText: data.searchText,
        limit: data.limit,
        offset: data.offset,
      };

      commit("UPDATE_ROLE_LOADING", true);
      return PUT(
        `api/v1/Club/${payloadRole.clubId}/role-members/update-role`,
        payloadRole
      )
        .then((response) => {
          commit("UPDATE_ROLE_LOADING", false);
          dispatch("notificationgenerator", {
            type: "success",
            message: "Changed User Role Successfully.",
          });
          dispatch("getUsersList", payloadSuccess);
          return;
        })
        .catch(() => {
          dispatch("notificationgenerator", {
            type: "error",
            message: "Something went wrong.",
          });
          commit("UPDATE_ROLE_LOADING", false);
        });
    },
    deleteUser({ commit, dispatch }: any, data: any) {
      const payloadSuccess = {
        clubId: data.clubId,
        searchText: data.searchText,
        limit: data.limit,
        offset: data.offset,
      };
      commit("DELETE_ROLE_MEMBER_LOADING", true);
      return DELETE(
        `api/v1/Club/${data.clubId}/role-members/${data.clubSystemUserId}`
      )
        .then((response) => {
          commit("DELETE_ROLE_MEMBER_LOADING", false);
          dispatch("notificationgenerator", {
            type: "success",
            message: "Deleted User Successfully.",
          });
          dispatch("getUsersList", payloadSuccess);
          return;
        })
        .catch(() => {
          dispatch("notificationgenerator", {
            type: "error",
            message: "Something went wrong.",
          });
          commit("DELETE_ROLE_MEMBER_LOADING", false);
        });
    },
  },
};
export default systemDashboard;
