import SystemDashboard from '../components/systemDashboard/SystemDashboard.vue'

const systemDashboardRoute = [
    {
        path: '/',
        name: 'SystemDashboard',
        component: SystemDashboard,
        meta: { requiresAuth: true },
    }
];

export default systemDashboardRoute;