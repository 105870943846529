import SnackBarModel from "@/models/snackBar/snackBarModel";
import { GET, PUT } from "@/service";

export type State = {
  user: any;
  updateErrors: any;

  status: SnackBarModel;
  loading: boolean;
  showMsg: boolean;
};

const state: State = {
  user: {},
  updateErrors: [],
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  loading: false,
  showMsg: false,
};

const user = {
  state,
  getters: {
    userDetails(state: { user: {} }) {
      return state.user;
    },
    loadingStatus(state: { loading: boolean }) {
      return state.loading;
    },
    updateErrors(state: { updateErrors: [] }) {
      return state.updateErrors;
    },
  },
  mutations: {
    SET_USER(state: { user: {} }, data: any) {
      state.user = data;
    },
    SET_UPDATE_ERRORS(state: { updateErrors: [] }, data: any) {
      state.updateErrors = data;
    },
    LOADING_STATUS(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
  },
  actions: {
    getUser({ commit }: any) {
      commit("LOADING_STATUS", true);
      return GET(`api/v1/User`)
        .then((response) => {
          commit("SET_USER", response.data);
          localStorage.setItem("user", JSON.stringify(response.data));
          commit("LOADING_STATUS", false);
        })
        .catch(() => {
          commit("LOADING_STATUS", false);
          commit("SET_USER", null);
        });
    },
    updateUser({ commit, dispatch }: any, payload: any) {
      commit("LOADING_STATUS", true);

      PUT(`api/v1/User`, payload)
        .then((response) => {
          dispatch("getUser", payload.id);
          var model = new SnackBarModel();
          model.type = "Success";
          model.Class = "status-success";
          model.Message = "Your profile information has been updated.";
          commit("CONTROL_STATUS", model);
          commit("LOADING_STATUS", false);
          commit("SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SHOW_MESSAGE", false);
          }, 2000);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
          commit("SET_UPDATE_ERRORS", error.response.data.errors);
        });
    },
  },
};
export default user;
