import { userValidation } from "../utils/helper";

const CampaignRoutes = [
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-allAudience",
    name: "EcAllAudience",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "All Audience",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/audience/allAudience/AllAudience.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/campaign-allAudience/audience-details/:memberId",
    name: "EcAudience",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "All Audience",
          to: {
            name: "EcAllAudience",
            props: true,
            query: {
              type: 2,
              key: 1,
            },
          },
        },
        {
          text: "Audience",
        },
      ],
      requiresAuth: true,
    },
    component: () => import("../components/clubDetails/members/memberDetails/memberDetails.vue"),
  },
  {
    path: "/club-details/:id/campaigns",
    name: "AllCampaign",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Email Campaign" },
      ],
      requiresAuth: true,
      authData: {
        permissionKey: "hasGetPermission",
        moduleCode: "emailCampaign",
        itemCode: "allCampaign",
        childItemCode: null,
      },
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/allCampaign/AllCampaign.vue"
      ),
  },
  {
    path: "/club-details/:id/create-campaign",
    name: "CreateCampaignStepOne",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        { text: "Create Campaign" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/createCampaign/createCampaignStepOne/CreateCampaignStepOne.vue"
      ),
  },
  {
    path: "/club-details/:id/create-campaign-step-two",
    name: "CreateCampaignStepTwo",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        { text: "Create Campaign" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/createCampaign/createCampaignStepTwo/CreateCampaignStepTwo.vue"
      ),
  },
  {
    path: "/club-details/:id/campaigns/:campaignId",
    name: "CampaignDetails",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        { text: "campaignName" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/campaignDetails/CampaignDetails.vue"
      ),
  },
  {
    path: "/club-details/:id/campaigns/:campaignId/edit",
    name: "CampaignEdit",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        { text: "campaignName" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/createCampaign/createCampaignStepTwo/CreateCampaignStepTwo.vue"
      ),
  },
  {
    path: "/club-details/:id/campaigns/:campaignId",
    name: "CampaignDetailsFlowList",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Flow Automation",
          to: {
            name: "flowAutomation",
            props: true,
          },
        },
        { text: "campaignName" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/campaignDetails/CampaignDetails.vue"
      ),
  },
  {
    path: "/club-details/:id/campaigns/:campaignId/edit",
    name: "CampaignEditFlowList",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Flow Automation",
          to: {
            name: "flowAutomation",
            props: true,
          },
        },
        { text: "campaignName" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/createCampaign/createCampaignStepTwo/CreateCampaignStepTwo.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/ec-configurefields",
    name: "ECConfigureFields",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        {
          text: "All Audience",
          to: {
            name: "EcAllAudience",
            props: true,
            params: true,
          },
        },
        {
          text: "Configure Fields",
        },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/audience/allAudience/configureFields/ConfigureFields.vue"
      ),
  },
  {
    path: "/club-details/:id/campaigns/:campaignId/analytics",
    name: "CampaignAnalytics",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Email Campaign",
          to: {
            name: "AllCampaign",
            props: true,
          },
        },
        { text: "campaignName" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/emailCampaignAnalytics/emailCampaignAnalyticsController/emailCampaignAnalyticsController.vue"
      ),
  },
  {
    path: "/club-details/:id/flow-create-campaign",
    name: "flowCreateCampaign",
    props: true,
    params: true,
    meta: {
      breadcrumb: [],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/createCampaign/createCampaignStepTwo/CreateCampaignStepTwo.vue"
      ),
  },
  {
    path: "/club-details/:id/flow-edit-campaign/:campaignId",
    name: "flowEditCampaign",
    props: true,
    params: true,
    meta: {
      breadcrumb: [],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/emailCampaign/createCampaign/createCampaignStepTwo/CreateCampaignStepTwo.vue"
      ),
  },
];

export default CampaignRoutes;
