import { userValidation } from "../utils/helper";

const voucherRoutes = [
  {
    path: "/club-details/:id/vouchers",
    name: "AllVoucher",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        { text: "Voucher Programs" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import("../components/clubDetails/allVoucher/AllVoucher.vue"),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/vouchers/:voucherCampaignId/manage-recipients",
    name: "VoucherManageRecipients",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        { text: "Manage Vouchers" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/allVoucher/manageRecipients/ManageRecipients.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/create-voucher",
    name: "CreateVoucherHomepage",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        { text: "Create Voucher Program" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/allVoucher/createVoucherHomepage/createVoucherHomepage.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/edit-voucher",
    name: "EditVoucherCampaign",
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        { text: "Edit programs" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/allVoucher/editVoucherCampaign/EditVoucherCampaign.vue"
      ),
  },
  {
    beforeEnter: () => {
      userValidation();
    },
    path: "/club-details/:id/create-voucher-campaign",
    name: "CreateVoucherCampaign",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        {
          text: "Create Voucher Program",
          to: {
            name: "CreateVoucherHomepage",
            props: true,
          },
        },
        { text: "Edit" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/allVoucher/createVoucher/createVoucherCampaign/CreateVoucherCampaign.vue"
      ),
  },
  {
    path: "/club-details/:id/vouchers/report",
    name: "VoucherReport",
    props: true,
    params: true,
    meta: {
      breadcrumb: [
        {
          text: "Home",
          to: {
            name: "SystemDashboard",
          },
        },
        {
          text: "clubname",
          to: {
            name: "ClubDashboard",
            props: true,
          },
        },
        {
          text: "Voucher Programs",
          to: {
            name: "AllVoucher",
            props: true,
          },
        },
        { text: "Report" },
      ],
      requiresAuth: true,
    },
    component: () =>
      import(
        "../components/clubDetails/allVoucher/voucherReport/VoucherReport.vue"
      ),
  },
];

export default voucherRoutes;
