
import { DELETE, GET, POST, PUT } from "@/service";


export const getSegmentList = (model: any) => {
    return GET(`api/v1/Segment/get-segment-list`, { params: model });
};
export const getSegment = (model: any) => {
    return GET(`api/v1/Segment/get-segment`, { params: model });
};

export const getSegmentConfig = (id: any) => {
    return GET(`api/v1/Segment/get-segment-configs?clubId=${id}`);
};

export const getSegmentDropdownList = (model: any) => {
    return GET(`api/v1/Segment/get-dropdown-list?Id=${model.id}&ClubId=${model.clubId}&EntityType=${model.entityType}`);
};

export const getSegmentConfigConditions = (payload: any = null) => {
    return GET(`api/v1/Segment/get-segment-config-conditions?clubId=${payload.clubId}?configGroup=${payload.configGroup}`);
};

export const addSegment = (model: any) => {
    return POST(`api/v1/Segment/add-segment`, model);
};

export const updateSegment = (model: any) => {
    return PUT(`api/v1/Segment/update-segment`, model);
};

export const renameSegment = (model: any) => {
    return PUT(`api/v1/Segment/rename-segment`, model);
};

export const removeSegments = (model: any) => {
    return DELETE(`api/v1/Segment/delete-segment`, { data: model });
};

