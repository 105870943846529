import { auth } from "../../services/auth.service";
import { configuration } from "@/configurationProvider";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import axios from "axios";
import {
  deleteVoucherRecipientsService,
  generataeVoucherCodeService,
  getVoucherRecipientsService,
  generateUnassignedVoucherCodeService,
  deleteUnassignedVoucherRecipientsService,
  assignAudienceToVoucherCodeService,
} from "@/services/clubDetails/voucherManageRecipients.service";

export type State = {
  columnList: any[];
  customFields: any[];
  customFieldsModifier: [];
  memberModalVisible: boolean;
  columnModifier: any[];
  members: any;
  items: any;
  attributeList: any[];
  setRecipients: any;
  searchQuery: any;
  advanceSearchQuery: any;
  voucherStatus: any;
  emailStatus: any;
  status: SnackBarModel;
  loading: boolean;
  showMsg: boolean;
  visible: boolean;
  attributeMap: any;
  successStatus: object;
  errorStatus: object;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
  isAdvanceFiltering: boolean;
  addVoucherModalVisibility: boolean;
  addVoucherLoading: boolean;
  deleteModalVisibility: boolean;
  assignModalVisibility: boolean;
  generatingVoucherCodeSnackbarVisibility: boolean;
  generatingVoucherStatusSnackbarVisibility: boolean;
  assignAudienceLoading: boolean;
};
const state: State = {
  columnList: [],
  columnModifier: [],
  customFields: [],
  customFieldsModifier: [],
  memberModalVisible: false,
  items: [],
  members: {},
  attributeList: [],
  setRecipients: {},
  searchQuery: "",
  advanceSearchQuery: "",
  voucherStatus: "",
  emailStatus: "",
  status: {
    type: "",
    Class: "",
    Message: "",
  },
  showMsg: false,
  loading: false,
  visible: false,
  attributeMap: [],
  successStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  errorStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  showErrorMsg: false,
  showSuccessMsg: false,
  isAdvanceFiltering: false,
  addVoucherModalVisibility: false,
  addVoucherLoading: false,
  deleteModalVisibility: false,
  assignModalVisibility: false,
  assignAudienceLoading: false,
  generatingVoucherCodeSnackbarVisibility: false,
  generatingVoucherStatusSnackbarVisibility: false,
};
const voucherManageRecipients = {
  state,
  getters: {},
  mutations: {
    TOGGLE_ADD_VOUCHER_MODAL_VISIBILITY(
      state: { addVoucherModalVisibility: any },
      data: any
    ) {
      state.addVoucherModalVisibility = data;
    },
    TOGGLE_DELETE_MODAL_VISIBILITY(
      state: { deleteModalVisibility: any },
      data: any
    ) {
      state.deleteModalVisibility = data;
    },
    SET_COLUMNS(state: { columnList: any }, data: any) {
      state.columnList = data;
    },
    SET_COLUMNSMODIFIER(state: { columnModifier: any }, data: any) {
      state.columnModifier = data;
    },
    SET_COLUMNS_CUSTOM_FIELDS(state: { customFields: any }, data: any) {
      state.customFields = data;
    },
    SET_COLUMNSMODIFIER_CUSTOM_FIELDS(
      state: { customFieldsModifier: any },
      data: any
    ) {
      state.customFieldsModifier = data;
    },
    SET_ATTRIBUTE(state: { attributeList: any }, data: any) {
      state.attributeList = data;
    },
    SET_RECIPIENTS(state: { setRecipients: any }, data: any) {
      state.setRecipients = data;
    },
    LOADING_STATUS(state: { loading: boolean }, data: any) {
      state.loading = data;
    },
    SET_SEARCH_QUERY(state: { searchQuery: any }, data: any) {
      state.searchQuery = data;
    },
    SET_ADVANCE_SEARCH_QUERY(state: { advanceSearchQuery: any }, data: any) {
      state.advanceSearchQuery = data;
    },
    SET_VOUCHER_STATUS(state: { voucherStatus: any }, data: any) {
      state.voucherStatus = data;
    },
    SET_EMAIL_STATUS(state: { emailStatus: any }, data: any) {
      state.emailStatus = data;
    },
    SET_SHOW_MESSAGE(state: { showMsg: boolean }, data: any) {
      state.showMsg = data;
    },
    SET_CONTROL_STATUS(state: { status: object }, data: any) {
      state.status = data;
    },
    SET_MODAL_VISIBLE(state: { visible: object }, data: any) {
      state.visible = data;
    },
    SET_ATTRIBUTE_MAP(state: { attributeMap: any }, data: any) {
      state.attributeMap = data;
    },
    SET_SUCCESS_STATUS(state: { successStatus: object }, data: any) {
      state.successStatus = data;
    },
    SET_ERROR_STATUS(state: { errorStatus: object }, data: any) {
      state.errorStatus = data;
    },
    SET_SUCCESS_MESSAGE_ISSHOW(state: { showSuccessMsg: boolean }, data: any) {
      state.showSuccessMsg = data;
    },
    SET_ERROR_MESSAGE_ISSHOW(state: { showErrorMsg: boolean }, data: any) {
      state.showErrorMsg = data;
    },
    SET_ADD_VOUCHER_LOADING(state: { addVoucherLoading: boolean }, data: any) {
      state.addVoucherLoading = data;
    },
    SET_ASSIGN_MODAL_VISIBILITY(
      state: { assignModalVisibility: boolean },
      data: any
    ) {
      state.assignModalVisibility = data;
    },
    SET_ASSIGN_AUDIENCE_LOADING(
      state: { assignAudienceLoading: boolean },
      data: any
    ) {
      state.assignAudienceLoading = data;
    },
    SET_GENERATING_VOUCHER_CODE_SNACKBAR_VISIBILITY(
      state: { generatingVoucherCodeSnackbarVisibility: boolean },
      data: any
    ) {
      state.generatingVoucherCodeSnackbarVisibility = data;
    },
    SET_GENERATING_VOUCHER_STATUS_SNACKBAR_VISIBILITY(
      state: { generatingVoucherStatusSnackbarVisibility: boolean },
      data: any
    ) {
      state.generatingVoucherStatusSnackbarVisibility = data;
    },
  },
  actions: {
    getVoucherRecipients({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);
      getVoucherRecipientsService(payload)
        .then((response) => {
          commit("SET_RECIPIENTS", response.data.data);
          commit("LOADING_STATUS", false);
          commit("SET_SEARCH_QUERY", payload.searchQuery);
          commit("SET_VOUCHER_STATUS", payload.voucherStatus);
          commit("SET_EMAIL_STATUS", payload.emailStatus);
          if (
            payload.voucherCode ||
            payload.minCreatedTime ||
            payload.maxCreatedTime ||
            payload.dateFilterType ||
            payload.dateFilterStartDate ||
            payload.dateFilterEndDate ||
            payload.amountFilterType ||
            payload.amountFilterValue
          ) {
            commit("SET_ADVANCE_SEARCH_QUERY", true);
          } else {
            commit("SET_ADVANCE_SEARCH_QUERY", false);
          }
        })
        .catch(() => {
          commit("SET_RECIPIENTS", []);
          commit("LOADING_STATUS", false);
        });
    },

    async voucherRecipientsExport({ commit }: any, payload: any) {
      commit("LOADING_STATUS", true);

      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Vouchers/${payload.voucherCampaignId}/recipients/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      }).then((response) => {
        commit("LOADING_STATUS", false);
        if (response?.data?.type) {
          commit("SET_SHOW_MESSAGE", true);
          const model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message = "Exported audience successfully.";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 4000);
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = "Voucher_recipients.xlsx";
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        } else {
          const model = new SnackBarModel();
          model.type = "warning";
          model.Class = "status-error";
          model.Message = "No reports to download.";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 4000);
        }
      });
    },

    deleteVoucherRecipients({ commit, dispatch }: any, payload: any) {
      deleteVoucherRecipientsService(payload)
        .then((response) => {
          var model = {
            clubId: payload.clubId,
            voucherCampaignId: payload.voucherCampaignId,
          };

          dispatch("getVoucherRecipients", model);

          if (response.data?.data?.errorCount) {
            var emodel = new SnackBarModel();
            emodel.type = "error";
            emodel.Class = "status-error";
            emodel.Message = `Couldn't delete ${response.data?.data?.errorCount} vouchers as they are in use by recipients.`;
            commit("SET_CONTROL_STATUS", emodel);
            commit("SET_SHOW_MESSAGE", true);
            setTimeout(() => {
              commit("SET_SHOW_MESSAGE", false);
            }, 4000);
          }

          if (response.data?.data?.successCount) {
            var emodel = new SnackBarModel();
            emodel.type = "success";
            emodel.Class = "status-error";
            emodel.Message = `Successfully deleted ${response.data?.data?.successCount} vouchers from the list.`;
            commit("SET_CONTROL_STATUS", emodel);
            commit("SET_SHOW_MESSAGE", true);
            setTimeout(() => {
              commit("SET_SHOW_MESSAGE", false);
            }, 3000);
          }
        })
        .catch((error) => {});
    },
    deleteUnassignedVoucherRecipients({ commit, dispatch }: any, payload: any) {
      deleteUnassignedVoucherRecipientsService(payload)
        .then((response) => {
          const { voucherRecipientId, ...model } = payload;
          dispatch("getVoucherRecipients", model);
          if (response.data?.data) {
            var emodel = new SnackBarModel();
            emodel.type = "success";
            emodel.Class = "status-error";
            emodel.Message = `Successfully deleted the voucher from the list.`;
            commit("SET_CONTROL_STATUS", emodel);
            commit("SET_SHOW_MESSAGE", true);
            setTimeout(() => {
              commit("SET_SHOW_MESSAGE", false);
            }, 3000);
          }
        })
        .catch((error) => {
          var emodel = new SnackBarModel();
          emodel.type = "error";
          emodel.Class = "status-error";
          emodel.Message = `${error?.response?.data?.Status?.Message}`;
          commit("SET_CONTROL_STATUS", emodel);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 4000);
        });
    },
    generateVoucherCode({ commit, dispatch }: any, payload: any) {
      // dispatch("getVoucherRecipients", payload);
      var model = {
        voucherCampaignRecipientIds: payload.voucherCampaignRecipientIds,
        voucherCampaignId: payload.voucherCampaignId,
        productCode: "",
        clubId: payload.clubId,
      };
      generataeVoucherCodeService(model)
        .then(() => {
          var payloadRecipient = {
            clubId: payload.clubId,
            voucherCampaignId: payload.voucherCampaignId,
            searchText: payload.searchText,
            limit: payload.limit,
            offset: payload.offset,
          };
          dispatch("getVoucherRecipients", payloadRecipient);
        })
        .catch(() => {});
    },
    generateUnassignedVoucherCode({ commit, dispatch }: any, payload: any) {
      commit("SET_ADD_VOUCHER_LOADING", true);
      generateUnassignedVoucherCodeService(payload)
        .then(() => {
          commit("SET_ADD_VOUCHER_LOADING", false);
          commit("SET_GENERATING_VOUCHER_CODE_SNACKBAR_VISIBILITY", true);

          const payloadRecipient = {
            clubId: payload.clubId,
            voucherCampaignId: payload.voucherCampaignId,
            limit: payload.limit,
            offset: payload.offset,
          };
          dispatch("getVoucherRecipients", payloadRecipient);
        })
        .catch(() => {
          commit("SET_ADD_VOUCHER_LOADING", false);
        });
    },
    assignAudienceToVoucherCode({ commit, dispatch }: any, payload: any) {
      commit("SET_ASSIGN_AUDIENCE_LOADING", true);
      const payloadForAssign = {
        voucherCampaignId: payload.voucherCampaignId,
        voucherRecipientId: payload.voucherRecipientId,
        audienceId: payload.audienceId,
        clubId: payload.clubId,
      };

      const { audienceId, ...payloadForRefetch } = payload;
      assignAudienceToVoucherCodeService(payloadForAssign)
        .then(() => {
          commit("SET_ASSIGN_AUDIENCE_LOADING", false);
          dispatch("getVoucherRecipients", payloadForRefetch);

          const model = new SnackBarModel();
          model.type = "success";
          model.Class = "status-error";
          model.Message =
            "Voucher code assigned to selected audience successfully.";
          commit("SET_CONTROL_STATUS", model);
          commit("SET_SHOW_MESSAGE", true);
          setTimeout(() => {
            commit("SET_SHOW_MESSAGE", false);
          }, 4000);
        })
        .catch(() => {
          commit("SET_ASSIGN_AUDIENCE_LOADING", false);
        });
    },
  },
};
export default voucherManageRecipients;
